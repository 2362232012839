import React from "react";


const Ads = () => {

  return (
    <div>
      <p># Adapex.io ads.txt File
      # Last updated 01-19-21
      #GLOBAL ADS.TXT
      
      #banner
      #33across
      appnexus.com, 10239, RESELLER, f5ab79cb980f11d1
      yahoo.com, 57289, RESELLER, e1a5b5b6e3255540
      rubiconproject.com, 16414, RESELLER, 0bfd66d529a55807
      openx.com, 537120563, RESELLER, 6a698e2ec38604c6
      indexexchange.com, 191973, RESELLER, 50b1c356f2c5c8fc
      rubiconproject.com, 21642, RESELLER, 0bfd66d529a55807
      pubmatic.com, 156423, RESELLER, 5d62403b186f2ace
      33across.com, 0010b00002PIxPJAA1, DIRECT, bbea06d9c4d2853c
      rubiconproject.com, 16414, RESELLER, 0bfd66d529a55807
      rubiconproject.com, 21642, RESELLER, 0bfd66d529a55807
      appnexus.com, 10239, RESELLER, f5ab79cb980f11d1
      adtech.com, 12094, RESELLER
      aol.com, 47594, RESELLER, e1a5b5b6e3255540
      indexexchange.com, 183635, RESELLER, 50b1c356f2c5c8fc
      openx.com, 537120563, RESELLER, 6a698e2ec38604c6
      openx.com, 540995201, RESELLER, 6a698e2ec38604c6
      pubmatic.com, 156423, RESELLER, 5d62403b186f2ace
      advangelists.com, 8d3bba7425e7c98c50f52ca1b52d3735, RESELLER, 60d26397ec060f98
      
      #Adagio
      adagio.io, 1088, DIRECT
      rubiconproject.com, 19116, RESELLER, 0bfd66d529a55807
      pubmatic.com, 159110, RESELLER, 5d62403b186f2ace
      improvedigital.com, 1790, RESELLER
      
      #AdaptMX
      amxrtb.com, 105199386, DIRECT
      indexexchange.com, 191503, RESELLER
      appnexus.com, 11786, RESELLER
      appnexus.com, 9393, RESELLER #Video #Display
      appnexus.com, 3153, RESELLER, f5ab79cb980f11d1
      appnexus.com, 11924, RESELLER, f5ab79cb980f11d1
      smartadserver.com, 3056, RESELLER
      Appnexus.com, 1356, RESELLER, f5ab79cb980f11d1
      appnexus.com, 1908, RESELLER, f5ab79cb980f11d1
      lijit.com, 260380, RESELLER, fafdf38b16bf6b2b
      sovrn.com, 260380, RESELLER, fafdf38b16bf6b2b
      openx.com, 538959099, RESELLER, 6a698e2ec38604c6
      pubmatic.com, 137711, RESELLER, 5d62403b186f2ace
      rubiconproject.com, 17960, RESELLER, 0bfd66d529a55807
      
      #AMAZON
      aps.amazon.com, 1ad7261b-91ea-4b6f-b9e9-b83522205b75, DIRECT
      indexexchange.com, 192410, Reseller, 50b1c356f2c5c8fc
      aps.amazon.com,1ad7261b-91ea-4b6f-b9e9-b83522205b75,DIRECT
      aps.amazon.com,aae5fce7-148c-42d1-b4a2-3be1a1ffbd25,DIRECT
      pubmatic.com,157150,RESELLER,5d62403b186f2ace
      appnexus.com,3663,RESELLER,f5ab79cb980f11d1
      openx.com,540191398,RESELLER,6a698e2ec38604c6
      rubiconproject.com,18020,RESELLER,0bfd66d529a55807
      appnexus.com,1908,RESELLER,f5ab79cb980f11d1
      adtech.com,12068,RESELLER,e1a5b5b6e3255540
      districtm.io,100962,RESELLER,3fd707be9c4527c3
      ad-generation.jp,12474,RESELLER
      districtm.io,100962,RESELLER,3fd707be9c4527c3
      rhythmone.com,1654642120,RESELLER,a670c89d4a324e47
      yahoo.com,55029,RESELLER,e1a5b5b6e3255540
      
      #AOL
      aol.com, 54981, DIRECT
      yahoo.com, 57340, RESELLER
      adtech.com, 10329, DIRECT
      advertising.com, 28319, DIRECT
      advertising.com, 20871, DIRECT
      #Verizon OB
      advertising.com, 28572, DIRECT
      Yahoo.com, 54981, DIRECT, e1a5b5b6e3255540
      
      #Appnexus
      appnexus.com, 7847, DIRECT, f5ab79cb980f11d1
      
      #Brealtime
      emxdgt.com, 68, DIRECT, 1e1d41537f7cad7f
      appnexus.com, 1356, RESELLER, f5ab79cb980f11d1
      google.com, pub-5995202563537249, RESELLER, f08c47fec0942fa0
      
      #ConnectAd
      connectad.io, 122, DIRECT
      pubmatic.com, 156077, RESELLER, 5d62403b186f2ace
      pubmatic.com, 55990, RESELLER, 5d62403b186f2ace
      openx.com, 537145117, RESELLER, 6a698e2ec38604c6
      adform.com, 768, RESELLER
      indexexchange.com, 190906, DIRECT, 50b1c356f2c5c8fc
      appnexus.com, 8738, RESELLER, f5ab79cb980f11d1
      EMXDGT.com, 1138, DIRECT, 1e1d41537f7cad7f
      appnexus.com, 1356, RESELLER, f5ab79cb980f11d1
      google.com, pub-5995202563537249, RESELLER, f08c47fec0942fa0
      sovrn.com, 244287, DIRECT, fafdf38b16bf6b2b
      lijit.com, 244287, DIRECT, fafdf38b16bf6b2b
      lijit.com, 244287-eb, DIRECT, fafdf38b16bf6b2b
      appnexus.com, 1360, RESELLER, f5ab79cb980f11d1
      gumgum.com, 11645, RESELLER, ffdef49475d318a9
      openx.com, 538959099, RESELLER, 6a698e2ec38604c6
      openx.com, 539924617, RESELLER, 6a698e2ec38604c6
      pubmatic.com, 137711, RESELLER, 5d62403b186f2ace
      pubmatic.com, 156212, RESELLER, 5d62403b186f2ace
      pubmatic.com, 156700, RESELLER, 5d62403b186f2ace
      rubiconproject.com, 17960, RESELLER, 0bfd66d529a55807
      yahoo.com, 55248, DIRECT
      rubiconproject.com, 13132, RESELLER, 0bfd66d529a55807
      rubiconproject.com, 17250, RESELLER, 0bfd66d529a55807
      xad.com, 240, RESELLER, 81cbf0a75a5e0e9a
      
      #Consumable
      consumable.com, 2000208, DIRECT
      aolcloud.net, 10947, DIRECT
      google.com, pub-6694481294649483, DIRECT
      consumable.com, 2000760, DIRECT
      adtech.com, 10947, DIRECT
      appnexus.com, 7556, DIRECT
      emxdgt.com, 20, DIRECT
      indexexchange.com, 184914, DIRECT
      indexexchange.com, 186248, DIRECT
      lijit.com, 248396, DIRECT
      openx.com, 537150004, DIRECT
      pubmatic.com, 156319, DIRECT
      sonobi.com, e55fb5d7c2, DIRECT
      sovrn.com, 248396, DIRECT
      tremorhub.com, vtrdn-wjdav, DIRECT
      rubiconproject.com, 17632, DIRECT
      openx.com, 539699341, DIRECT
      rubiconproject.com, 18890, DIRECT
      pubmatic.com, 157367, DIRECT
      indexexchange.com, 187454, DIRECT
      sonobi.com, 6e5cfb5420, DIRECT
      advertising.com, 28409, DIRECT
      spotxchange.com, 270977, DIRECT
      spotx.tv, 270977, DIRECT
      telaria.com, vtrdn-wjdav, DIRECT
      lijit.com, 248396-eb, DIRECT
      advertising.com, 28509, DIRECT
      yahoo.com, 55104, DIRECT
      pubmatic.com, 159117, DIRECT
      tremorhub.com, vtrdn-ysjam, DIRECT
      telaria.com, vtrdn-ysjam, DIRECT
      
      #Conversant
      yahoo.com, 55771, RESELLER, e1a5b5b6e3255540
      conversantmedia.com, 42046, DIRECT, 03113cd04947736d
      conversantmedia.com, 40824, DIRECT, 03113cd04947736d
      appnexus.com, 4052, RESELLER, f5ab79cb980f11d1
      openx.com, 540031703, RESELLER, 6a698e2ec38604c6
      openx.com, 540031703, RESELLER, 6a698e2ec38604c6
      contextweb.com, 561998, RESELLER, 89ff185a4c4e857c
      pubmatic.com, 158100, RESELLER, 5d62403b186f2ace
      yahoo.com, 55771, RESELLER, e1a5b5b6e3255540
      
      #Districtm
      districtm.io, 101099, DIRECT, 3fd707be9c4527c3
      appnexus.com, 1908, RESELLER, f5ab79cb980f11d1
      google.com, pub-9685734445476814, RESELLER, f08c47fec0942fa0
      districtm.io, 101132, DIRECT, 3fd707be9c4527c3
      #google
      google.com, pub-7171208642811509, DIRECT, f08c47fec0942fa0
      google.com, pub-1062972861553303, DIRECT, f08c47fec0942fa0
      google.com, mb-app-pub-1062972861553303, DIRECT, f08c47fec0942fa0
      google.com, video-pub-1062972861553303, DIRECT, f08c47fec0942fa0
      
      #eplanning
      e-planning.net,59723682023370d7,DIRECT,c1ba615865ed87b2
      improvedigital.com,1556,RESELLER
      rhythmone.com,1767353092,RESELLER,a670c89d4a324e47
      sovrn.com,268876,DIRECT,fafdf38b16bf6b2b
      lijit.com,268876,DIRECT,fafdf38b16bf6b2b
      openx.com,541031350,RESELLER,6a698e2ec38604c6
      rubiconproject.com,12186,RESELLER,0bfd66d529a55807
      pubmatic.com,156631,RESELLER,5d62403b186f2ace
      
      #GourmetAds
      themediagrid.com, H6MJW1, DIRECT, 35d5010d7789b49d
      appnexus.com, 1792, DIRECT, f5ab79cb980f11d1
      gourmetads.com, 11201, DIRECT
      gourmetads.com, 11242, DIRECT
      gourmetads.com, 12032, DIRECT
      gourmetads.com, 11250, DIRECT
      gourmetads.com, 12080, DIRECT
      gourmetads.com, 12034, DIRECT
      gourmetads.com, 12033, DIRECT
      gourmetads.com, 11234, DIRECT
      gourmetads.com, 11206, DIRECT
      gourmetads.com, 11243, DIRECT
      gourmetads.com, 11205, DIRECT
      gourmetads.com, 11199, DIRECT
      gourmetads.com, 11203, DIRECT
      gourmetads.com, 11198, DIRECT
      gourmetads.com, 11253, DIRECT
      gourmetads.com, 11204, DIRECT
      gourmetads.com, 11254, DIRECT
      gourmetads.com, 11252, DIRECT
      gourmetads.com, 11554, DIRECT
      gourmetads.com, 11553, DIRECT
      gourmetads.com, 11555, DIRECT
      gourmetads.com, 11717, DIRECT
      gourmetads.com, 11729, DIRECT
      gourmetads.com, 11730, DIRECT
      gourmetads.com, 11800, DIRECT
      gourmetads.com, 11791, DIRECT
      gourmetads.com, 11935, DIRECT
      
      #GUMGUM
      gumgum.com, 13285, RESELLER, ffdef49475d318a9
      appnexus.com, 1001, RESELLER, f5ab79cb980f11d1
      appnexus.com, 1942, RESELLER, f5ab79cb980f11d1
      appnexus.com, 2758, RESELLER, f5ab79cb980f11d1
      appnexus.com, 3135, RESELLER, f5ab79cb980f11d1
      bidtellect.com, 1407, RESELLER, 1c34aa2d85d45e93
      contextweb.com, 558355, RESELLER, 89ff185a4c4e857c
      criteo.com, 109412, DIRECT, 9fac4a4a87c2a44f
      openx.com, 537120563, RESELLER, 6a698e2ec38604c6
      openx.com, 537149485, RESELLER, 6a698e2ec38604c6
      pubmatic.com, 50758, RESELLER, 5d62403b186f2ace
      spotx.tv, 147949, RESELLER, 7842df1d2fe2db34
      spotxchange.com, 147949, RESELLER, 7842df1d2fe2db34
      google.com, pub-9557089510405422, RESELLER, f08c47fec0942fa0
      google.com, pub-3848273848634341, RESELLER, f08c47fec0942fa0
      rhythmone.com, 78519861, RESELLER, a670c89d4a324e47
      outbrain.com,01a755b08c8c22b15d46a8b753ab6955d4,RESELLER
      outbrain.com,01a755b08c8c22b15d46a8b753ab6955d4,DIRECT
      appnexus.com, 7597, RESELLER, f5ab79cb980f11d1
      lkqd.com, 470, RESELLER, 59c49fa9598a0117
      lkqd.net, 470, RESELLER, 59c49fa9598a0117
      33across.com,0013300001r0t9mAAA,RESELLER
      appnexus.com, 10239, RESELLER, f5ab79cb980f11d1
      rubiconproject.com, 16414, RESELLER, 0bfd66d529a55807
      pubmatic.com, 156423, RESELLER, 5d62403b186f2ace
      pubmatic.com, 157897, RESELLER, 5d62403b186f2ace
      openx.com, 539392223, RESELLER, 6a698e2ec38604c6
      rhythmone.com, 2439829435, RESELLER, a670c89d4a324e47
      emxdgt.com, 326, RESELLER, 1e1d41537f7cad7f
      gumgum.com, 13285, DIRECT, ffdef49475d318a9
      appnexus.com,1001,reseller,f5ab79cb980f11d1
      appnexus.com,2758,reseller,f5ab79cb980f11d1
      appnexus.com,3135,reseller,f5ab79cb980f11d1
      bidtellect.com,1407,reseller,1c34aa2d85d45e93
      contextweb.com,558355,reseller,
      openx.com,537120563,reseller,6a698e2ec38604c6
      openx.com,537149485,reseller,6a698e2ec38604c6
      google.com,pub-9557089510405422,reseller,f08c47fec0942fa0
      google.com,pub-3848273848634341,reseller,f08c47fec0942fa0
      rhythmone.com,78519861,reseller,a670c89d4a324e47
      appnexus.com,7597,reseller,f5ab79cb980f11d1
      33across.com,0013300001r0t9mAAA,reseller,bbea06d9c4d2853c
      appnexus.com,10239,reseller,f5ab79cb980f11d1
      rubiconproject.com,16414,reseller,0bfd66d529a55807
      pubmatic.com,156423,reseller,5d62403b186f2ace
      openx.com,539392223,reseller,6a698e2ec38604c6
      pubmatic.com,157897,reseller,5d62403b186f2ace
      synacor.com,82151,reseller,e108f11b2cdf7d5b
      coxmt.com,2000067995202,reseller,
      appnexus.com,9316,reseller,f5ab79cb980f11d1
      pubmatic.com,156344,reseller,5d62403b186f2ace
      rubiconproject.com,13344,reseller,0bfd66d529a55807
      EMXDGT.com,1284,reseller,1e1d41537f7cad7f
      appnexus.com,1356,reseller,f5ab79cb980f11d1
      indexexchange.com,190966,reseller,50b1c356f2c5c8fc
      rhythmone.com,905992537,reseller,a670c89d4a324e47
      outbrain.com,00254374f0c468f3b2732db17fd42cb6e5,reseller,
      
      #IndexExchange
      indexexchange.com, 184311, DIRECT, 50b1c356f2c5c8fc
      #IndexExchange OB
      indexexchange.com, 187063, DIRECT, 50b1c356f2c5c8fc
      
      #Openx
      openx.com, 540323914, RESELLER, 6a698e2ec38604c6
      openx.com, 537144083, RESELLER, 6a698e2ec38604c6
      openx.com, 540251318, RESELLER, 6a698e2ec38604c6
      
      #Onetag
      onetag.com, 642455ab8f71fec, DIRECT
      
      #Pubmatic
      pubmatic.com, 148803, DIRECT, 5d62403b186f2ace
      #Pubmatic OB
      pubmatic.com, 157246, DIRECT, 5d62403b186f2ace
      
      #Rubicon
      rubiconproject.com, 17262, RESELLER, 0bfd66d529a55807
      rubiconproject.com, 17262, DIRECT, 0bfd66d529a55807
      #Rubicon OB
      rubiconproject.com, 18132, DIRECT, 0bfd66d529a55807
      rubiconproject.com, 18132, RESELLER, 0bfd66d529a55807
      
      #Rhythmone OB
      rhythmone.com, 1022945102, DIRECT, a670c89d4a324e47
      #Rhythmone
      rhythmone.com, 1223411471, DIRECT, a670c89d4a324e47
      
      #Sharethough
      sharethrough.com, c7f387ce, DIRECT, d53b998a7bd4ecd2
      indexexchange.com, 186046, RESELLER, 50b1c356f2c5c8fc
      indexexchange.com, 186046, RESELLER
      spotxchange.com, 212457, RESELLER, 7842df1d2fe2db34
      spotxchange.com, 212457, RESELLER
      spotx.tv, 212457, RESELLER, 7842df1d2fe2db34
      spotx.tv, 212457, RESELLER
      pubmatic.com, 156557, RESELLER, 5d62403b186f2ace
      pubmatic.com, 156557, RESELLER
      spotxchange.com, 212457, RESELLER, 7842df1d2fe2db34
      spotx.tv, 212457, RESELLER, 7842df1d2fe2db34
      rubiconproject.com,18694,RESELLER,0bfd66d529a55807
      openx.com,540274407,RESELLER,6a698e2ec38604c6
      pubmatic.com, 158723, RESELLER, 5d62403b186f2ace
      rubiconproject.com, 18694, RESELLER, 0bfd66d529a55807
      openx.com, 540274407, RESELLER, 6a698e2ec38604c6
      pubmatic.com, 158723, RESELLER
      rubiconproject.com, 18694, RESELLER
      33across.com, 0013300001kQj2HAAS, RESELLER
      
      #Smaato
      smaato.com, 1100041059, DIRECT, 07bcf65f187117b4
      smaato.com, 1100004890, DIRECT, 07bcf65f187117b4
      bidmachine.io, 60, RESELLER
      blis.com, 86, RESELLER, 61453ae19a4b73f4
      engagebdr.com, 16, RESELLER
      pokkt.com, 5886, RESELLER, c45702d9311e25fd
      rhythmone.com, 4201299756, RESELLER, a670c89d4a324e47
      admanmedia.com, 552, DIRECT
      pubnative.net, 1007194, RESELLER, d641df8625486a7
      badtiming.com, a-105, RESELLER, bf66753b8f380142
      openx.com, 540421297, RESELLER, 6a698e2ec38604c6
      pubmatic.com, 156425, RESELLER, 5d62403b186f2ace
      
      #Sonobi
      rhythmone.com, 1059622079, RESELLER, a670c89d4a324e47
      contextweb.com, 560606, RESELLER, 89ff185a4c4e857c
      sonobi.com, 65dad693cc, DIRECT, d1a215d9eb5aee9e
      
      #Sonobi OB
      sonobi.com, 88a5b885c6, DIRECT, d1a215d9eb5aee9e
      
      #Sovrn OB
      sovrn.com, 259022, DIRECT, fafdf38b16bf6b2b
      lijit.com, 259022, DIRECT, fafdf38b16bf6b2b
      lijit.com, 259022-eb, DIRECT, fafdf38b16bf6b2b
      appnexus.com, 1360, RESELLER, f5ab79cb980f11d1
      gumgum.com, 11645, RESELLER, ffdef49475d318a9
      openx.com, 538959099, RESELLER, 6a698e2ec38604c6
      openx.com, 539924617, RESELLER, 6a698e2ec38604c6
      pubmatic.com, 137711, RESELLER, 5d62403b186f2ace
      pubmatic.com, 156212, RESELLER, 5d62403b186f2ace
      pubmatic.com, 156700, RESELLER, 5d62403b186f2ace
      rubiconproject.com, 17960, RESELLER, 0bfd66d529a55807
      
      #Teads
      teads.tv, 17018, DIRECT, 15a9c44f6d26cbe1
      
      #Trion
      trioninteractive.com, 30557, DIRECT, 513d07ac0be57f88
      yahoo.com, 56857, DIRECT
      appnexus.com, 11176, DIRECT
      districtm.io, 101733, RESELLER, 3fd707be9c4527c3
      appnexus.com, 1908, RESELLER, f5ab79cb980f11d1
      google.com, pub-8415754845210549, RESELLER, f08c47fec0942fa0
      pubmatic.com, 159543, RESELLER, 5d62403b186f2ace
      
      #Triplelift
      triplelift.com, 7456, DIRECT, 6c33edb13117fd86
      triplelift.com, 3762, DIRECT, 6c33edb13117fd86
      
      #unruly
      rhythmone.com,1022945102,DIRECT,a670c89d4a324e47
      video.unrulymedia.com,1022945102, DIRECT
      indexexchange.com, 182257, RESELLER
      appnexus.com, 6849, RESELLER
      rubiconproject.com, 15268, RESELLER
      spotxchange.com, 285547, RESELLER, 7842df1d2fe2db34
      spotx.tv, 285547, RESELLER, 7842df1d2fe2db34
      pubmatic.com, 159277, RESELLER, 5d62403b186f2ace
      advertising.com, 28605, RESELLER
      
      #Yieldmo OB
      yieldmo.com, 2335578239436530449, DIRECT
      #Yieldmo
      yieldmo.com, 1779615309230671174, DIRECT
      contextweb.com, 561118, RESELLER, 89ff185a4c4e857c #yieldmo
      appnexus.com, 7911, RESELLER #yieldmo
      rubiconproject.com, 17070, RESELLER, 0bfd66d529a55807 #yieldmo
      pubnative.net, 1007284, RESELLER, d641df8625486a7b #yieldmodisplay
      pubnative.net, 1007285, RESELLER, d641df8625486a7b #yieldmonative
      pubnative.net, 1007286, RESELLER, d641df8625486a7b #yieldmovideo
      onetag.com, 664e107d9f2b748, RESELLER #yieldmo
      
      #native&video
      #nextmillenium
      nextmillennium.io, 15147, DIRECT, 65bd090fa4a1e3d6
      gumgum.com, 13165, DIRECT, ffdef49475d318a9
      gumgum.com, 13615, RESELLER, ffdef49475d318a9
      districtm.io, 101200, DIRECT, 3fd707be9c4527c3
      appnexus.com, 1908, RESELLER, f5ab79cb980f11d1
      appnexus.com, 7944, RESELLER, f5ab79cb980f11d1
      rubiconproject.com, 17888, DIRECT, 0bfd66d529a55807
      rubiconproject.com, 19142, DIRECT, 0bfd66d529a55807
      openx.com, 540224251, RESELLER, 6a698e2ec38604c6
      openx.com, 540255321, RESELLER, 6a698e2ec38604c6
      openx.com, 540233824, RESELLER, 6a698e2ec38604c6
      openx.com, 540225743, RESELLER, 6a698e2ec38604c6
      indexexchange.com, 188029, DIRECT
      indexexchange.com, 188333, DIRECT
      google.com, pub-7269238500499280, RESELLER, f08c47fec0942fa0
      google.com, pub-8606804482029683, RESELLER, f08c47fec0942fa0
      aps.amazon.com, 79e40b05-e673-4b6c-85f9-79252a7f96a5, DIRECT
      pubmatic.com, 157150, RESELLER, 5d62403b186f2ace
      districtm.io, 100962, RESELLER
      openx.com, 540191398, RESELLER, 6a698e2ec38604c6
      yldbt.com, 5b522cc167f6b300b89dc6d3, RESELLER, cd184cb30abaabb5
      coxmt.com, 2000068024302, RESELLER
      pubmatic.com, 157689, RESELLER, 5d62403b186f2ace
      pubmatic.com, 157577, RESELLER, 5d62403b186f2ace
      undertone.com, 3757, DIRECT
      appnexus.com, 2234, RESELLER
      openx.com, 537153564, RESELLER, 6a698e2ec38604c6
      springserve.com, 743, DIRECT, a24eb641fc82e93d
      beachfront.com, 4969, RESELLER, e2541279e8e2ca4d
      advertising.com, 26282, RESELLER
      appnexus.com, 9284, RESELLER, f5ab79cb980f11d1
      conversantmedia.com, 41868, DIRECT
      video.unrulymedia.com, UNRX-PUB-79ae5563-e8e5-40d2-8446-b661c8e95d52, DIRECT
      indexexchange.com, 182257, RESELLER
      appnexus.com, 6849, RESELLER
      rubiconproject.com, 15268, RESELLER
      spotxchange.com, 250862, RESELLER, 7842df1d2fe2db34
      spotx.tv, 250862, RESELLER, 7842df1d2fe2db34
      teads.tv, 18141, DIRECT, 15a9c44f6d26cbe1
      rhythmone.com, 316817661, DIRECT, a670c89d4a324e47
      rhythmone.com, 2059465938, DIRECT, a670c89d4a324e47
      video.unrulymedia.com, 316817661, DIRECT
      rhythmone.com, 316817661, DIRECT, a670c89d4a324e47
      smartadserver.com, 1827, RESELLER
      improvedigital.com, 335, RESELLER
      appnexus.com, 3538, RESELLER
      appnexus.com, 3539, RESELLER
      appnexus.com, 3540, RESELLER
      contextweb.com, 562019, RESELLER, 89ff185a4c4e857c
      openx.com, 540752851, RESELLER, 6a698e2ec38604c6
      appnexus.com, 7118, RESELLER
      spotx.tv, 108933, RESELLER, 7842df1d2fe2db34
      spotxchange.com, 108933, RESELLER, 7842df1d2fe2db34
      improvedigital.com, 185, RESELLER
      adform.com, 183, RESELLER
      freewheel.tv, 33081, RESELLER
      freewheel.tv, 33601, RESELLER
      google.com, pub-8172268348509349, RESELLER, f08c47fec0942fa0
      yieldmo.com, 2230523609997805235, DIRECT
      outbrain.com, 0012d9f0ac51b05ef9b320793a03ba4a9c, DIRECT
      appnexus.com, 7597, RESELLER, f5ab79cb980f11d1
      triplelift.com, 7001, DIRECT, 6c33edb13117fd86
      appnexus.com, 1314, RESELLER
      facebook.com, 590126751405962, RESELLER, c3e20eee3f780d68
      
      #Primis
      Sekindo.com, 21588, DIRECT, b6b21d256ef43532
      spotxchange.com, 84294, RESELLER, 7842df1d2fe2db34
      spotx.tv, 84294, RESELLER, 7842df1d2fe2db34
      advertising.com, 7372, RESELLER
      advertising.com, 24410, RESELLER
      pubmatic.com, 156595, RESELLER, 5d62403b186f2ace
      google.com, pub-1320774679920841, RESELLER, f08c47fec0942fa0
      openx.com, 540258065, RESELLER, 6a698e2ec38604c6
      rubiconproject.com, 20130, RESELLER, 0bfd66d529a55807
      freewheel.tv, 19129, RESELLER
      freewheel.tv, 19133, RESELLER
      tremorhub.com, mb9eo-oqsbf, RESELLER, 1a4e959a1b50034a
      telaria.com, mb9eo-oqsbf, RESELLER, 1a4e959a1b50034a
      smartadserver.com, 3436, RESELLER
      rhythmone.com, 2338962694, RESELLER, a670c89d4a324e47
      video.unrulymedia.com, 2338962694, RESELLER
      indexexchange.com, 191923, RESELLER
      
      #STN
      google.com, pub-9505823743978337, RESELLER, f08c47fec0942fa0
      tremorhub.com, pi979-clt5j, RESELLER, 1a4e959a1b50034a
      tremorhub.com, pi979-n0ct6, RESELLER, 1a4e959a1b50034a
      spotxchange.com, 138942, RESELLER, 7842df1d2fe2db34
      spotx.tv, 138942, RESELLER, 7842df1d2fe2db34
      Advertising.com, 11299, RESELLER
      advertising.com, 28791, RESELLER
      appnexus.com, 8354, RESELLER
      openx.com, 539363661, RESELLER, 6a698e2ec38604c6
      openx.com, 540021061, RESELLER, 6a698e2ec38604c6
      contextweb.com, 560622, RESELLER
      indexexchange.com, 187621, RESELLER, 50b1c356f2c5c8fc
      indexexchange.com, 187780, RESELLER, 50b1c356f2c5c8fc
      lkqd.net, 508, RESELLER, 59c49fa9598a0117
      rubiconproject.com, 18782, RESELLER, 0bfd66d529a55807
      rubiconproject.com, 18784, RESELLER, 0bfd66d529a55807
      telaria.com, pi979-clt5j, RESELLER, 1a4e959a1b50034a
      telaria.com, pi979-n0ct6, RESELLER, 1a4e959a1b50034a
      undertone.com, 3590, RESELLER
      pubmatic.com, 159710, RESELLER, 5d62403b186f2ace
      gumgum.com,14123,RESELLER,ffdef49475d318a9
      EMXDGT.com, 1855, RESELLER, 1e1d41537f7cad7f
      Appnexus.com, 1356, RESELLER, f5ab79cb980f11d1
      spotx.tv, 262786, RESELLER, 7842df1d2fe2db34
      spotxchange.com, 262786, RESELLER, 7842df1d2fe2db34
      google.com, pub-9070629843129312, RESELLER, f08c47fec0942fa0
      google.com, pub-5717092533913515, RESELLER, f08c47fec0942fa0
      indexexchange.com, 185192, RESELLER
      pubmatic.com, 157904, RESELLER, 5d62403b186f2ace
      appnexus.com, 2234, RESELLER
      openx.com, 537153564, RESELLER, 6a698e2ec38604c6
      rubiconproject.com, 22412, RESELLER, 0bfd66d529a55807
      advertising.com, 28650, RESELLER
      
      #Vdopia EB
      vdopia.com, 13790, DIRECT #video
      chocolateplatform.com, 13790, DIRECT #video
      vdopia.com, 13790, DIRECT #banner
      chocolateplatform.com, 13790, DIRECT #banner
      
      #Viewdeos # 18/03/2019
      adform.com, 1819, RESELLER, 9f5210a2f0999e32
      advertising.com, 12287, RESELLER
      advertising.com, 20871, RESELLER
      advertising.com, 23089, RESELLER
      advertising.com, 28319, RESELLER
      aniview.com, 57173f460757bb6c428b465b, RESELLER, 78b21b97965ec3f8
      appnexus.com, 1356, RESELLER, f5ab79cb980f11d1
      appnexus.com, 1908, RESELLER, f5ab79cb980f11d1
      appnexus.com, 7847, RESELLER
      appnexus.com, 9316, RESELLER, f5ab79cb980f11d1
      aps.amazon.com, d1dd54fa-53ea-4197-83d4-a648ea31e0b3, RESELLER
      aralego.com, par-D2337B42BA884D77CD786A7E26AB9683, RESELLER
      beachfront.com, 4174, RESELLER, e2541279e8e2ca4d
      c1exchange.com, c1x_a9_uampubs, RESELLER
      contextweb.com, 558458, RESELLER, 89ff185a4c4e857c
      contextweb.com, 558604, RESELLER, 89ff185a4c4e857c
      contextweb.com, 561562, RESELLER, 89ff185a4c4e857c
      coxmt.com, 2000068024302, RESELLER
      districtm.io, 100962, RESELLER
      districtm.io, 101099, RESELLER
      districtm.io, 101132, RESELLER
      districtm.io, 101661, RESELLER, 3fd707be9c4527c3
      EMXDGT.com, 68, RESELLER, 1e1d41537f7cad7f
      freewheel.tv, 13755, RESELLER
      freewheel.tv, 13945, RESELLER
      freewheel.tv, 328705, RESELLER
      freewheel.tv, 328721, RESELLER
      freewheel.tv, 772017, RESELLER
      freewheel.tv, 774081, RESELLER
      google.com, pub-1062972861553303, RESELLER, f08c47fec0942fa0
      google.com, pub-1539993421461332, RESELLER, f08c47fec0942fa0
      google.com, pub-5746030484598936, RESELLER
      google.com, pub-5995202563537249, RESELLER, f08c47fec0942fa0
      google.com, pub-6331119926849614, RESELLER
      google.com, pub-7002491002409919, RESELLER, f08c47fec0942fa0
      google.com, pub-7171208642811509, RESELLER, f08c47fec0942fa0
      google.com, pub-7538555282033458, RESELLER, f08c47fec0942fa0
      google.com, pub-8586265011624107, RESELLER, f08c47fec0942fa0
      google.com, pub-9378724246417115, RESELLER, f08c47fec0942fa0
      google.com, pub-9685734445476814, RESELLER, f08c47fec0942fa0
      improvedigital.com, 907, RESELLER
      indexexchange.com, 183965, RESELLER, 50b1c356f2c5c8fc
      indexexchange.com, 184311, RESELLER
      lkqd.net, 39, RESELLER, 59c49fa9598a0117
      openx.com, 539961786, RESELLER
      pubmatic.com, 148803, RESELLER, 5d62403b186f2ace
      pubmatic.com, 156344, RESELLER, 5d62403b186f2ace
      pubmatic.com, 156377, RESELLER, 5d62403b186f2ace
      pubmatic.com, 157246, RESELLER, 5d62403b186f2ace
      pubmatic.com, 157607, RESELLER, 5d62403b186f2ace
      rhythmone.com, 1755648630, RESELLER a670c89d4a324e47
      rubiconproject.com, 13344, RESELLER, 0bfd66d529a55807
      rubiconproject.com, 16568, RESELLER, 0bfd66d529a55807
      rubiconproject.com, 17262, RESELLER, 0bfd66d529a55807
      rubiconproject.com, 18020, RESELLER, 0bfd66d529a55807
      rubiconproject.com, 18126, RESELLER, 0bfd66d529a55807
      smartadserver.com, 2058, RESELLER
      spotx.tv, 146631, RESELLER, 7842df1d2fe2db34
      spotxchange.com, 146631, RESELLER, 7842df1d2fe2db34
      synacor.com,82171, RESELLER, e108f11b2cdf7d5b
      ucfunnel.com, par-D2337B42BA884D77CD786A7E26AB9683, DIRECT
      video.unrulymedia.com, UNRX-PUB-b7b8af13-0a12-4d37-8b27-0dc8aa90e3e2, RESELLER
      
      #END Adapex ads.txt
      #Publisher should insert site specific ads.txt starting here


      # 21-01-2021
      viewdeos.com, 5f7da24b47df4e639c6c6ed5, DIRECT
      advertising.com, 12287, DIRECT
      advertising.com, 20871, RESELLER
      advertising.com, 23089, RESELLER
      advertising.com, 28319, RESELLER
      advertising.com, 28572, DIRECT
      aniview.com, 57173f460757bb6c428b465b, RESELLER, 78b21b97965ec3f8
      appnexus.com, 10044, DIRECT, f5ab79cb980f11d1
      appnexus.com, 1356, RESELLER, f5ab79cb980f11d1
      appnexus.com, 1908, RESELLER, f5ab79cb980f11d1
      appnexus.com, 4052, RESELLER
      appnexus.com, 7847, RESELLER, f5ab79cb980f11d1
      appnexus.com, 9382, RESELLER, f5ab79cb980f11d1
      aps.amazon.com, d1dd54fa-53ea-4197-83d4-a648ea31e0b3, RESELLER
      aralego.com, par-D2337B42BA884D77CD786A7E26AB9683, DIRECT
      beachfront.com, 4174, RESELLER, e2541279e8e2ca4d
      c1exchange.com, c1x_a9_uampubs, RESELLER
      chocolateplatform.com, 13790, RESELLER
      contextweb.com, 558604, RESELLER, 89ff185a4c4e857c
      contextweb.com, 560606, RESELLER, 89ff185a4c4e857c
      contextweb.com, 561562, RESELLER, 89ff185a4c4e857c
      contextweb.com, 561998, RESELLER, 89ff185a4c4e857c
      conversantmedia.com, 100195, DIRECT, 03113cd04947736d
      coxmt.com, 2000068024302, RESELLER
      didna.io, 4169, DIRECT
      districtm.io, 100962, RESELLER
      districtm.io, 101099, RESELLER
      districtm.io, 101132, RESELLER
      freewheel.tv, 1157665, RESELLER
      freewheel.tv, 1157681, RESELLER
      freewheel.tv, 1163521, RESELLER
      freewheel.tv, 1164257, RESELLER
      google.com, pub-1062972861553303, RESELLER, f08c47fec0942fa0
      google.com, pub-1539993421461332, RESELLER, f08c47fec0942fa0
      google.com, pub-2930805104418204, RESELLER, f08c47fec0942fa0
      google.com, pub-3565385483761681, DIRECT, f08c47fec0942fa0
      google.com, pub-3769010358500643, RESELLER, f08c47fec0942fa0
      google.com, pub-4586415728471297, RESELLER, f08c47fec0942fa0
      google.com, pub-4903453974745530, RESELLER, f08c47fec0942fa0
      google.com, pub-5157263156975427, DIRECT, f08c47fec0942fa0
      google.com, pub-5717092533913515, RESELLER, f08c47fec0942fa0
      google.com, pub-5746030484598936, RESELLER, f08c47fec0942fa0
      google.com, pub-5995202563537249, RESELLER, f08c47fec0942fa0
      google.com, pub-6331119926849614, RESELLER, f08c47fec0942fa0
      google.com, pub-7171208642811509, RESELLER, f08c47fec0942fa0
      google.com, pub-8586265011624107, RESELLER, f08c47fec0942fa0
      google.com, pub-9685734445476814, RESELLER, f08c47fec0942fa0
      improvedigital.com, 1581, RESELLER
      indexexchange.com, 184311, RESELLER, 50b1c356f2c5c8fc
      indexexchange.com, 185192, DIRECT #EBDA
      indexexchange.com, 186905, DIRECT, 50b1c356f2c5c8fc
      indexexchange.com, 187063, DIRECT, 50b1c356f2c5c8fc
      indexexchange.com, 187063, RESELLER, 50b1c356f2c5c8fc
      indexexchange.com, 191740, RESELLER, 50b1c356f2c5c8fc
      onetag.com, 57e618150c70d90, RESELLER
      openx.com, 539961786, RESELLER, 6a698e2ec38604c6
      pubmatic.com, 148803, RESELLER, 5d62403b186f2ace
      pubmatic.com, 156344, RESELLER, 5d62403b186f2ace
      pubmatic.com, 156377, RESELLER, 5d62403b186f2ace
      pubmatic.com, 157246, RESELLER, 5d62403b186f2ace
      pubmatic.com, 158100, RESELLER, 5d62403b186f2ace
      pubmatic.com, 158683, DIRECT, 5d62403b186f2ace
      pubmatic.com, 159744, DIRECT, 5d62403b186f2ace
      rhythmone.com, 1022945102, RESELLER, a670c89d4a324e47
      rhythmone.com, 1059622079, RESELLER, a670c89d4a324e47
      rhythmone.com, 144927031, RESELLER, a670c89d4a324e47
      rhythmone.com, 3350674472, DIRECT, a670c89d4a324e47
      rubiconproject.com, 11006, RESELLER, 0bfd66d529a55807
      rubiconproject.com, 13344, RESELLER, 0bfd66d529a55807
      rubiconproject.com, 16696, RESELLER, 0bfd66d529a55807
      rubiconproject.com, 17262, RESELLER, 0bfd66d529a55807
      rubiconproject.com, 18020, RESELLER, 0bfd66d529a55807
      rubiconproject.com, 18132, DIRECT, 0bfd66d529a55807
      rubiconproject.com, 18132, RESELLER, 0bfd66d529a55807
      rubiconproject.com, 20100, DIRECT, 0bfd66d529a55807
      rubiconproject.com, 7716, RESELLER, 0bfd66d529a55807
      sonobi.com, 844f617a46, RESELLER, d1a215d9eb5aee9e
      sonobi.com, 88a5b885c6, DIRECT, d1a215d9eb5aee9e
      spotx.tv, 108706, DIRECT, 7842df1d2fe2db34
      spotx.tv, 152838, RESELLER, 7842df1d2fe2db34
      spotxchange.com, 152838, RESELLER, 7842df1d2fe2db34
      spotxchange.com,108706, DIRECT, 7842df1d2fe2db34
      synacor.com, 82171, RESELLER, e108f11b2cdf7d5b
      ucfunnel.com, par-D2337B42BA884D77CD786A7E26AB9683, DIRECT
      vdopia.com, 13790, RESELLER
      video.unrulymedia.com, 144927031, DIRECT
      video.unrulymedia.com, 3350674472, DIRECT
      yahoo.com, 55771, RESELLER, e1a5b5b6e3255540
      yieldmo.com, 2335578239436530449, RESELLER
</p>

    </div>
  );
};

export default Ads;
