import React from "react";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Grid from "@material-ui/core/Grid";
import { auth, db } from "../../../firebase";
// import SignUpInfo from "./SignUpInfo/SignUpInfo";
// import EmailVerify from "./EmailVerify/EmailVerify";
import axios from "axios";
import firebaseConfig from "../../../config/firebaseConfig";
const formStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: 300
  }
}));

// determines whether the signin or signup elements are displayed

export default function SignUp() {
  const classes = formStyles();
  const [processing, setProcessing] = React.useState(false);
  const [errors, setErrors] = React.useState({
    errorUN: false,
    errorTextUN: "Username is already in use by another account.",
    errorEmail: false,
    errorTextEmail: "Invalid email / email is already in use.",
    errorPW: false,
    errorTextPW: "Passwords do not match.",
    errorUNPattern: false,
    errorTextUNPattern: 'Username must contain only alphanumeric values'
  });
  const [values, setValues] = React.useState({
    email: "",
    username: "",
    password: "",
    passwordConfirm: "",
    weight: "",
    weightRange: "",
    showPassword: false,
    checkedA: false,
    ageCheck: false,
    available: true,
    accountCreated: false,
    emailVerified: false
  });

  const delayedQuery = _.debounce(q => checkPasswords(q), 100);

  const handleChange = prop => event => {
    setValues({
      ...values,
      [prop]: event.target.value
    });

    if (prop === "email" && errors.errorEmail) {
      setErrors({
        ...errors,
        errorEmail: false
      });
    }

    if (prop === "username" && errors.errorUN) {
      setErrors({
        ...errors,
        errorUN: false
      });
    }

    if (prop === "username") {
      checkPattern(event)
      //check for regexp in event.target.value if any special chars show error
      //  delayedPatternCheck({ prop: prop, value: event.target.value });
    }

    if (prop === "password" || prop === "passwordConfirm") {
      // call debounce function
      delayedQuery({ prop: prop, value: event.target.value });
    }
  };

  const handleCheckedChange = prop => event => {
    setValues({
      ...values,
      [prop]: event.target.checked
    });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
    console.log(event);
  };

  const checkPattern = event => {
    if (!event) return;
    let value = event.target.value
    let regex = /\W|_/g;
    if (regex.test(value) && !values["errorUNPattern"]) {
      setErrors({
        ...errors,
        errorUNPattern: true,
        errorUN: false
      });
    }
    else {
      setErrors({
        ...errors,
        errorUNPattern: false,
        errorUN: false
      });
    }
  }

  const checkPasswords = event => {
    if (!event) return;
    if (values["password"] && values["passwordConfirm"] && event.value) {
      if (
        event.prop === "password" &&
        event.value === values["passwordConfirm"]
      ) {
        setErrors({
          ...errors,
          errorPW: false
        });
      } else if (
        event.prop === "passwordConfirm" &&
        event.value === values["password"]
      ) {
        setErrors({
          ...errors,
          errorPW: false
        });
      } else {
        setErrors({
          ...errors,
          errorPW: true
        });
      }
    } else {
      setErrors({
        ...errors,
        errorPW: false
      });
    }
  };

  const fetchUsernames = async () => {
    // call axios function to check useernmae
    try {
      let response = await db.ref("userMap/" + values["username"]).once('value');
      console.log('response', response.val());
      let val = response.val()
      if (val) {
         return { usernameExists: true };
      }
      else {
         return { usernameExists: false };
      }
     
    } catch (err) {
      console.log('err', err)
    }
  };

  const checkUsername = async username => {
    return new Promise(async (resolve, reject) => {
      let index = await fetchUsernames();
      if (index.usernameExists) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const createAccount = async event => {
    event.preventDefault();
    if (errors.errorPW || errors.errorUNPattern) {
      return;
    }
    setProcessing(true);
    let duplicateUsername = await checkUsername(values.username);
    if (duplicateUsername) {
      setErrors({
        ...errors,
        errorUN: true
      });
      setProcessing(false);
      return;
    }
    auth
      .createUserWithEmailAndPassword(values.email, values.password)
      .then(async (res) => {
        console.log("res", res);
        if (!auth.currentUser) {
          setProcessing(false);
          return;
        }
        let user = auth.currentUser;
        

        if (user) {
            let response = await axios.post(
              `${firebaseConfig.functionURL}/addUser`,
                { username: values.username, uid: user.uid, email: values.email },
                {
                  headers: {
                    "Access-Control-Allow-Origin": "*"
                  }
                }
            );
            // user.sendEmailVerification();
            setValues({
              ...values,
              accountCreated: true
            });
            console.log("response", response);
            setProcessing(false);
        }
        
      })
      .catch(error => {
        setProcessing(false);
        if (error.code === "auth/email-already-in-use") {
          setErrors({
            ...errors,
            errorEmail: true
          });
          console.log("errors", errors);
        }
      });

    if (values.accountCreated === true) {
      console.log(values.accountCreated);
    }
  };

  // if (values.accountCreated === true && values.emailVerified === false) {
  //   return <EmailVerify></EmailVerify>;
  // }

  // if (values.accountCreated === true && values.emailVerified === true) {
  //   return <SignUpInfo />;
  // } 
  // if (values.accountCreated !== true || values.emailVerified !== true)  {
    return (
      <div className="signIn">
        <Backdrop
          className={classes.backdrop}
          open={processing}
          style={{ zIndex: "1000000" }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="sign-in-info-container">
          <Grid container direction="row" justify="center" alignItems="center">
            <form onSubmit={createAccount}>
              <Grid item xs={12}>
                <FormControl
                  required
                  error={errors.errorUN}
                  className={clsx(classes.margin, classes.textField)}
                  variant="filled"
                >
                  <InputLabel htmlFor="filled-adornment-email">
                    Username
                  </InputLabel>
                  <FilledInput
                    onChange={handleChange("username")}
                    id="filled-adornment-email"
                    type="text"
                  />
                  <FormHelperText
                    hidden={!errors.errorUN}
                    error={errors.errorUN}
                  >
                    {errors.errorTextUN}
                  </FormHelperText>
                  <FormHelperText
                    hidden={!errors.errorUNPattern}
                    error={errors.errorUNPattern}
                  >
                    {errors.errorTextUNPattern}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  required
                  error={errors.errorEmail}
                  className={clsx(classes.margin, classes.textField)}
                  variant="filled"
                >
                  <InputLabel htmlFor="filled-adornment-email">
                    Email
                  </InputLabel>
                  <FilledInput
                    onChange={handleChange("email")}
                    id="filled-adornment-email"
                    type="email"
                  />
                  <FormHelperText
                    hidden={!errors.errorEmail}
                    error={errors.errorEmail}
                  >
                    {errors.errorTextEmail}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  required
                  className={clsx(classes.margin, classes.textField)}
                  variant="filled"
                >
                  <InputLabel htmlFor="filled-adornment-password">
                    Password
                  </InputLabel>
                  <FilledInput
                    id="filled-adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange("password")}
                    endAdornment={
                      <InputAdornment position="end">
                        {" "}
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  required
                  error={errors.errorPW}
                  className={clsx(classes.margin, classes.textField)}
                  variant="filled"
                >
                  <InputLabel htmlFor="filled-adornment-password">
                    Confirm Password
                  </InputLabel>
                  <FilledInput
                    id="filled-adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    value={values.passwordConfirm}
                    onChange={handleChange("passwordConfirm")}
                    endAdornment={
                      <InputAdornment position="end">
                        {" "}
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText
                    hidden={!errors.errorPW}
                    error={errors.errorPW}
                  >
                    {errors.errorTextPW}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}  style={{display:'flex', alignItems:'center'}}>
                <FormControlLabel
                style={{margin:'0px'}}
                  control={
                    <Checkbox
                    required
                    checked={values.checkedA}
                    onChange={handleCheckedChange("checkedA")}
                    value="checkedA"
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                  />
                  }
                  // label="I agree to the Terms of Service and Privacy Policy"
                  label=''
                />
                <FormHelperText id="my-helper-text"> I agree to the <a rel="noopener noreferrer" href='https://uploads-ssl.webflow.com/5e16a1353c053fffe8818a76/5f07ffe96644894dce6d55ee_ClickMidasLLCTermsofService7092020.pdf' target='_blank'>Terms of Service</a> and <a rel="noopener noreferrer" href='https://www.iubenda.com/privacy-policy/27494930' target='_blank'>Privacy Policy</a> </FormHelperText>
              </Grid>
              <Grid item xs={12}  style={{display:'flex', alignItems:'center'}}>
                <FormControlLabel
                style={{margin:'0px'}}
                  control={
                    <Checkbox
                    required
                    checked={values.ageCheck}
                    onChange={handleCheckedChange("ageCheck")}
                    value="ageCheck"
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                  />
                  }
                  // label="I agree to the Terms of Service and Privacy Policy"
                  label=''
                />
                <FormHelperText id="my-helper-text" className={classes.age}> I confirm that I am of the age of at least 13 years or older. </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  SIGN UP
                </Button>
              </Grid>
            </form>
          </Grid>
        </div>
      </div>
    );
  // }
}
