import React, { createContext, Component } from "react";
import { auth } from "../firebase";
export const UserContext = createContext();

class UserProvider extends Component {
  state = {
    user: null
  };
  unsubscribeFromAuth = null;

  componentDidMount = async () => {
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
      if (userAuth && userAuth.uid && !userAuth.isAnonymous) {
        const token = await auth.currentUser.getIdTokenResult();
        this.setState({ user: { ...userAuth, token } });
      } else {
        this.setState({ user: null });
      }
    });
  };

  componentWillUnmount = () => {
    this.unsubscribeFromAuth();
  };

  render() {
    const { user } = this.state;
    const { children } = this.props;

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
  }
}
export default UserProvider;
