import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import SignIn from "./SignIn/SignIn.component";
import SignUp from "./SignUp/SignUp.component";
import { UserContext } from "../../providers/UserProvider";
import { navigate } from "@reach/router";

let tabIndex = 0;

const tabIndexChangeHandler = (event, value, index) => {
  tabIndex = value;
};

const stylesTabs = theme => ({
  default_tabStyle: {
    color: "#333333",
    backgroundColor: "#efefef"
  },

  active_tabStyle: {
    color: "#ffffff",
    backgroundColor: "#c5a24a"
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  }
}));

export default function FullWidthTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const user = useContext(UserContext);

  useEffect(() => {
    if (user) {
      console.log("already signed in!", user);
      navigate("/dashboard");
    }
  }, [user]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    tabIndexChangeHandler("", newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  return (
    <div className={classes.root} style={{marginTop:'4vh'}}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          centered
        >
          <Tab label="SIGN IN" {...a11yProps(0)} />
          <Tab label="SIGN UP" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
        alignCenter={true}
      >
        <TabPanel
          dir={theme.direction}
          className={
            tabIndex === 0 ? stylesTabs.active_tab : stylesTabs.default_tabStyle
          }
        >
          <SignIn />
        </TabPanel>
        <TabPanel dir={theme.direction}>
          <SignUp />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
