import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import SvgIcon from "@material-ui/core/SvgIcon";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { UserContext } from "../../providers/UserProvider";
import { navigate } from "@reach/router";
import PayPal from "../../components/PayPal/PayPal";
import { db, provider } from "../../firebase";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { auth } from "../../firebase";
import Check from "@material-ui/icons/Check";
import { CreatorContext } from "../../providers/CreatorProvider";
import firebaseConfig from "../../config/firebaseConfig";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import axios from "axios";
import ytlogo from "../../assets/Images/yt_logo_dark.png";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// console.log(process.env);

const APP_URL =
  process.env.REACT_APP_DEV_ENV === "production" ? `https://app.clickmidas.com` : `https://staging-clickmidas.web.app`; 

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  margin: {
    marginRight: theme.spacing(6),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(0),
    color: "white"
  },
  root: {
    margin: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);
  const [options, setOptions] = useState([]);
  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
    if (props.pages) {
      let index = props.pages.findIndex(p => {
        return p.id === "userprofile";
      });
      if (props.pages && index < 0) {
        props.pages.push({ name: "User Profile", id: "userprofile" });
      }
    }

    setOptions(props.pages);
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  // const handleCancel = () => {
  //   onClose();
  // };

  const handleOk = () => {
    console.log("value on close", value);
    onClose(value);
  };

  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        Select Facebook Stream Source
      </DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {options.map((option, index) => (
            <FormControlLabel
              value={option.id}
              key={index}
              control={<Radio />}
              label={option.name}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk} color="secondary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired
};

const Dashboard = () => {
  const user = useContext(UserContext);
  const creator = useContext(CreatorContext);
  const [userVerified, setUserVerified] = useState(false);
  const [open, setOpen] = useState(false);
  const [earnings, setEarnings] = useState(null);
  const [signedInAlert, setSignedInAlert] = useState(false);
  const [earningsAlert, setEarningsAlert] = useState(false);
  const [connectedAlert, setConnectedAlert] = useState(false);
  const [requestExistsAlert, setRequestExistsAlert] = useState(false);
  const [requestAlert, setRequestAlert] = useState(false);
  const [emailVerificationAlert, setEmailVerificationAlert] = useState(false);
  const [emailFailedAlert, setEmailFailedAlert] = useState(false);
  const [linkCopiedAlert, setLinkCopiedAlert] = useState(false);
  const [processing, setProcessing] = useState(true);
  const [openFBPages, setopenFBPages] = React.useState(false);
  const [FbPagesList, setFbPagesList] = React.useState([]);
  const selectedFbSource = ""

  const instagramCredentials = {
    appID: "2901449303468877",
    secret: "25045cfdca19b78dbd74a3a365b84bff",
    redirectUri:
      process.env.GCLOUD_PROJECT === "old-ninja-production"
        ? "https://app.clickmidas.com/auth/instagram"
        : "https://staging-clickmidas.web.app/auth/instagram"
  };

  // const `alertClose` = stateSetter => {
  //   stateSetter(false);
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeFbModal = async pageID => {
    await db
      .ref(`creators/${user.uid}/facebook`)
      .update({ selectedSource: pageID });
    copyFacebookLink();
    setopenFBPages(false);
  };

  const getEarnings = async () => {
    try {
      const earningsRef = await db
        .ref(`earnings/${user.uid}/latest/`)
        .once("value");
      if (earningsRef.val()) {
        setEarnings(earningsRef.val());
      } else {
        setEarnings(0);
      }
    } catch (error) {
      console.log(error);
      setEarnings(0);
    }
  };

  const sendEmailVerification = async () => {
    if(user && user.uid){

      setProcessing(true);
      axios
      .post(
        `${firebaseConfig.functionURL}/sendEmailVerificationLink`,
        {
          email: user.email,
          uid: user.uid
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*"
          }
        }
      ).then(res => {
        res = res.data
        if(res.emailSent){
          setEmailVerificationAlert(true);
        }
        else {
          setEmailFailedAlert(true);
        }
        setProcessing(false);
      }).catch(err =>{
        console.log('err', err)
        setEmailFailedAlert(true);
        setProcessing(false);
      })
    }
    // sendEmailVerificationLink
    // auth.currentUser
    //   .sendEmailVerification()
    //   .then(() => {
    //     setEmailVerificationAlert(true);
    //   })
    //   .catch(err => {
    //     console.error(err);
    //     setEmailFailedAlert(true);
    //   })
    //   .finally(() => {
    //     setProcessing(false);
    //   });
  };

  const requestWithdrawal = async () => {
    setProcessing(true);
    if (!user) {
      setProcessing(false);
      setSignedInAlert(true);
      return;
    }
    const connected = creator && creator.paypalId;
    if (!connected) {
      setProcessing(false);
      setConnectedAlert(true);
      return;
    }
    if (earnings && earnings >= 25) {
      const payoutRef = await db
        .ref(`payoutRequests/${user.uid}`)
        .once("value");
      if (!payoutRef.val()) {
        await db.ref(`payoutRequests/${user.uid}`).set(true);
        setProcessing(false);
        setRequestAlert(true);
        return;
      } else {
        setProcessing(false);
        setRequestExistsAlert(true);
        return;
      }
    } else {
      setProcessing(false);
      setEarningsAlert(true);
      return;
    }
  };

  const copyTwitchLink = () => {
    if (user && creator && creator.username) {
      setProcessing(true);
      console.log("user", user, creator);
      navigator.clipboard.writeText(
        `${APP_URL}/stream/twitch/${creator.username}`
      );
      setProcessing(false);
      setLinkCopiedAlert(true);
    }
  };
  const handleTwitch = async () => {
    if (creator && creator.twitch) {
      copyTwitchLink();
    } else {
      window.open(
        `https://id.twitch.tv/oauth2/authorize?client_id=0tzpqx87ig6rqlnq5jhm7oul5a3fzc&redirect_uri=${APP_URL}/auth/twitch&response_type=code&scope=user_read+channel_read`,
        "_self"
      );
    }
  };

  const handleInstagram = async () => {
    if (creator && creator.instagram) {
      window.alert("Instagram already linked");
    } else {
      window.open(
        `https://api.instagram.com/oauth/authorize?client_id=${instagramCredentials.appID}&redirect_uri=${instagramCredentials.redirectUri}&scope=user_profile,user_media&response_type=code`
      );
    }
  };
  const copyYoutubeLink = () => {
    if (user) {
      setProcessing(true);
      console.log("user", user, creator);
      navigator.clipboard.writeText(
        `${APP_URL}/stream/youtube/${creator.username}`
      );
      setProcessing(false);
      setLinkCopiedAlert(true);
    }
  };
  const handleYouTube = async () => {
    console.log("creator", creator);
    if (creator && creator.youtube) {
      copyYoutubeLink();
    } else {
      console.log("is this working");
      provider.addScope("https://www.googleapis.com/auth/youtube.readonly");
      provider.addScope(
        "https://www.googleapis.com/auth/youtubepartner-channel-audit"
      );
      auth.currentUser.linkWithPopup(provider).then(res => {
        console.log("did i get here");
        axios
          .get(
            `https://www.googleapis.com/youtube/v3/channels?part=id&mine=true&key=AIzaSyDLoN10GUlG_gmXvyu0bb2xOPMLgP71YGY`,
            {
              headers: {
                Authorization: `Bearer ${res.credential.accessToken}`,
                Accept: "application/json"
              }
            }
          )
          .then(res => {
            console.log(res.data);
            console.log(res.data.items[0].id);
            db.ref(`creators/${user.uid}/youtube/`)
              .set(res.data)
              .then(() => {
                copyYoutubeLink();
              });
          })
          .catch(error => {
            console.log("fail: " + error);
          });
      });
    }
  };
  const handleFacebook = async res => {
    console.log("res", res);

    setProcessing(true);
    if (creator && creator.facebook) {
      creator.facebook.pages
        ? setFbPagesList(creator.facebook.pages)
        : setFbPagesList([]);
      setopenFBPages(true);
      setProcessing(false);
    } else {
      db.ref(`creators/${user.uid}/facebook/`)
        .set(res)
        .then(() => {
          axios
            .post(
              `${firebaseConfig.functionURL}/generateLink`,
              { uid: user.uid, platform: "facebook" },
              {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              }
            )
            .then(res => {
              res = res.data;
              console.log("facebook pages", res);
              setFbPagesList(res.pages.data);
              setopenFBPages(true);
              setTimeout(() => {
                setProcessing(false);
              }, 500);
            });
        });
    }
  };
  const copyFacebookLink = () => {
    if (user) {
      console.log("user", user, creator);
      navigator.clipboard.writeText(
        `${APP_URL}/stream/facebook/${creator.username}`
        // `localhost:3000/stream/facebook/${creator.username}`
      );
      setLinkCopiedAlert(true);
    }
  };

  const addUsernameToGam = async username => {
    console.log("username", username);
    if (!username) {
      return;
    }
    await axios
      .post(
        `${firebaseConfig.functionURL}/initializeGam`,
        { streamerId: username },
        {
          headers: {
            "Access-Control-Allow-Origin": "*"
          }
        }
      )
      .then(res => {
        res = res.data;
      });
  };
  useEffect(() => {
    if (user) {
      console.log('user in dashboard', user)
      if (user && user.emailVerified) {
        setUserVerified(true);
      }
      getEarnings();
    } else {
      navigate("/");
    }
    if (user && creator) {
      if (creator && !creator.gam) {
        addUsernameToGam(creator.username);
      }
      setTimeout(() => {
        setProcessing(false);
      }, 2000);
    }
  }, [user, creator]);
  const classes = useStyles();
  return (
    <div>
      <div hidden={userVerified && creator && creator.completedInfo}>
        <div
          style={{
            marginTop:'4vh',
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly"
          }}
        >
          <Card
            style={{
              maxWidth: "1001px",
              padding: "20px"
            }}
          >
            <CardContent style={{ textAlign: "center" }}>
              <h1 className="heading">User Verification</h1>
              <p>
                Before you can start using Midas, please complete these two
                steps.
              </p>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                  size="large"
                  variant="contained"
                  style={{
                    margin: "5px",
                    minWidth: "218px",
                    backgroundColor: "#D1AB4C"
                  }}
                  onClick={() => {
                    navigate("/account-info");
                  }}
                  disabled={creator && creator.completedInfo}
                >
                  <p className={classes.margin}>Complete Sign Up</p>
                  {creator && creator.completedInfo && <Check />}
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  style={{
                    margin: "5px",
                    minWidth: "218px"
                  }}
                  onClick={() => {
                    sendEmailVerification();
                  }}
                  disabled={user && user.emailVerified}
                >
                  <p className={classes.margin} style={{ color: "#595959" }}>
                    Verify Email Address
                  </p>
                  {user && user.emailVerified && <Check />}
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </div>
      <div hidden={!userVerified || !(creator && creator.completedInfo)}>
        <div
          style={{
            marginTop:'4vh',
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly"
          }}
        >
          <Card
            style={{
              maxWidth: "1001px",
              padding: "20px"
            }}
          >
            <CardContent style={{ textAlign: "center" }}>
              <h1 className="heading">Manage Links</h1>
              <p>
                Click your platform’s icon below to link your account or to copy
                your unique Midas link once your account has been linked.
              </p>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                  size="large"
                  variant="contained"
                  style={{
                    backgroundColor: "#6341A6",
                    margin: "5px",
                    minWidth: "218px"
                  }}
                  onClick={() => {
                    handleTwitch();
                  }}
                  // onClick={handleClickOpen}
                >
                  <SvgIcon style={{ color: "white" }}>
                    {/* <path d="M0 0v24h24v-24h-24zm19.642 14.791l-4.179 4.179h-3.104l-2.031 2.03h-2.149v-2.03h-3.821v-11.224l1.075-2.746h14.209v9.791zm-9.672 3.701l2.03-2.03h3.821l2.388-2.388v-7.641h-11.463v10.03h3.224v2.029zm4.418-9.313h1.433v4.175h-1.433v-4.175zm-3.821 0h1.433v4.175h-1.433v-4.175z" /> */}
                    <path
                      d="M11.64 5.93h1.43v4.28h-1.43m3.93-4.28H17v4.28h-1.43M7 2L3.43 5.57v12.86h4.28V22l3.58-3.57h2.85L20.57 12V2m-1.43 9.29l-2.85 2.85h-2.86l-2.5 2.5v-2.5H7.71V3.43h11.43z"
                      fill="white"
                    />
                  </SvgIcon>
                  <p className={classes.margin}>TWITCH</p>
                </Button>
                {/* <Button
                  size="large"
                  variant="contained"
                  style={{
                    backgroundColor: "#1FBAED",
                    margin: "5px",
                    minWidth: "218px"
                  }}
                >
                  <SvgIcon style={{ color: "white" }} viewBox="0 0 40 30">
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M3.276 1.833c-.817 0-1.599.307-2.203.855C-.178 3.844-.282 5.797.74 7.172l6.552 8.787l-6.595 8.869c-1.015 1.375-.937 3.328.339 4.484a3.271 3.271 0 0 0 4.824-.457l9.208-12.376a.866.866 0 0 0 0-1.015L5.896 3.145a3.248 3.248 0 0 0-2.62-1.312zm25.453 0a3.25 3.25 0 0 0-2.625 1.312l-9.145 12.297a.852.852 0 0 0 0 1.016l9.181 12.396a3.27 3.27 0 0 0 4.824.457c1.276-1.156 1.353-3.109.339-4.484l-6.595-8.869l6.557-8.787c1.016-1.375.917-3.328-.339-4.484a3.304 3.304 0 0 0-2.203-.855z"
                      fill="white"
                    />
                  </SvgIcon>
                  <p className={classes.margin}>MIXER</p>
                </Button> */}
                <FacebookLogin
                  appId="293911298391897"
                  // hidden={true}
                  fields=""
                  scope="user_videos, pages_show_list"
                  callback={res => {
                    console.log(res);
                    if (
                      (res && res.status !== "unknown") ||
                      (res && res.status !== "not_authorized")
                    ) {
                      handleFacebook(res);
                    }
                  }}
                  render={renderProps => (
                    <Button
                      // hidden={true}
                      disabled={true}
                      onClick={renderProps.onClick}
                      size="large"
                      variant="contained"
                      style={{
                        /* backgroundColor: "#38569F", */
                        margin: "5px",
                        minWidth: "218px",
                        backgroundColor: "gray",
                        opacity: '.2'
                      }}
                    >
                      <SvgIcon
                        style={{ color: "white" }}
                        viewBox="0 0 1200 1000"
                      >
                        <path
                          xmlns="http://www.w3.org/2000/svg"
                          d="M124.074 1000V530.771H0V361.826h124.074V217.525C124.074 104.132 197.365 0 366.243 0C434.619 0 485.18 6.555 485.18 6.555l-3.984 157.766s-51.564-.502-107.833-.502c-60.9 0-70.657 28.065-70.657 74.646v123.361h183.331l-7.977 168.945H302.706V1000H124.074"
                          fill="white"
                        />
                      </SvgIcon>
                      <p className={classes.margin}>FACEBOOK</p>
                    </Button>
                  )}
                />

                <ConfirmationDialogRaw
                  classes={{
                    paper: classes.paper
                  }}
                  pages={FbPagesList}
                  id="fb-source-list"
                  keepMounted
                  open={openFBPages}
                  onClose={closeFbModal}
                  value={selectedFbSource}
                />

                <Button
                  size="large"
                  variant="contained"
                  // hidden={true}
                  disabled={true} // change to true
                  style={{
                   /* backgroundColor: "#333333", */
                    margin: "5px",
                    minWidth: "218px",
                    padding:'10px 0px 10px 0px',
                    backgroundColor: "gray", 
                    opacity: '.2'
                  }}
                  onClick={() => {
                    handleYouTube();
                  }}
                >
                  <img
                    style={{ width: "100px", height:'60%' }}
                    src={ytlogo}
                    alt="Youtube"
                  ></img>
                  {/* <SvgIcon style={{ color: "white" }} viewBox="0 0 25 25">
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M21.593 7.203a2.506 2.506 0 0 0-1.762-1.766C18.265 5.007 12 5 12 5s-6.264-.007-7.831.404a2.56 2.56 0 0 0-1.766 1.778c-.413 1.566-.417 4.814-.417 4.814s-.004 3.264.406 4.814c.23.857.905 1.534 1.763 1.765c1.582.43 7.83.437 7.83.437s6.265.007 7.831-.403a2.515 2.515 0 0 0 1.767-1.763c.414-1.565.417-4.812.417-4.812s.02-3.265-.407-4.831zM9.996 15.005l.005-6l5.207 3.005l-5.212 2.995z"
                      fill="white"
                    />
                  </SvgIcon>
                  <p className={classes.margin}>YOUTUBE</p> */}
                </Button>
              </Grid>
              <Grid item sm={12} md={4}>
                  <Button
                    hidden={true}
                    size="large"
                    variant="contained"
                    style={{
                      backgroundColor: "#71DE8C",
                      color: "white",
                      marginTop: "10px"
                    }}
                    onClick={handleInstagram}
                  >
                    Link instagram
                  </Button>
                </Grid>
              <p
                style={{
                  'text-align': 'center'
                }
                }
              >Facebook and Youtube Support Coming Soon!</p>
            </CardContent>
          </Card>
        </div>
        <div
          style={{
            marginTop: "50px",
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly"
          }}
        >
          <Card
            style={{
              maxWidth: "1200px",
              width: "1000px",
              padding: "20px"
            }}
          >
            <CardContent style={{ textAlign: "center" }}>
              <h1 className="heading">Payments</h1>
              <p>
                When your current balance reaches $25 or more, you are elligible
                to withdraw your Midas earnings.
                <br />
                Please ensure your preferred payment information is linked in
                your account settings before requesting a withdrawal.
              </p>

              <p style={{ textAlign: "left" }}>Current Balance:</p>
              <Grid container>
                <Grid item sm={12} md={4}>
                  <p className="balance-box">
                    {earnings || earnings === 0 ? "$" + (earnings/100).toFixed(2) : "Loading..."}
                  </p>
                </Grid>
                <Grid item sm={12} md={4}>
                  <Button
                    size="large"
                    variant="contained"
                    style={{
                      backgroundColor: "#71DE8C",
                      color: "white",
                      marginTop: "10px"
                    }}
                    onClick={handleClickOpen}
                  >
                    UPDATE PAYMENT INFO
                  </Button>
                </Grid>
                <Grid item sm={12} md={4}>
                  <Button
                    size="large"
                    variant="contained"
                    style={{ marginTop: "10px" }}
                    onClick={requestWithdrawal}
                    disabled={!earnings || earnings < 25}
                  >
                    REQUEST WITHDRAWAL
                  </Button>
                </Grid>
                
              </Grid>
            </CardContent>
          </Card>
        </div>
      </div>
      <PayPal handleClose={handleClose} open={open} />
      <Snackbar
        open={signedInAlert}
        autoHideDuration={6000}
        onClose={() => {
          setSignedInAlert(false);
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            setSignedInAlert(false);
          }}
        >
          You are not logged in - please sign in and try again!
        </Alert>
      </Snackbar>
      <Snackbar
        open={signedInAlert}
        autoHideDuration={6000}
        onClose={() => {
          setSignedInAlert(false);
        }}
      >
        <Alert severity="error">
          You are not logged in - please sign in and try again!
        </Alert>
      </Snackbar>
      <Snackbar
        open={connectedAlert}
        autoHideDuration={6000}
        onClose={() => {
          setConnectedAlert(false);
        }}
      >
        <Alert severity="error">
          PayPal not set up - please connect your PayPal account!
        </Alert>
      </Snackbar>
      <Snackbar
        open={requestExistsAlert}
        autoHideDuration={6000}
        onClose={() => {
          setRequestExistsAlert(false);
        }}
      >
        <Alert severity="error">
          Request Already Exists - You have already requested a payout.
        </Alert>
      </Snackbar>
      <Snackbar
        open={requestAlert}
        autoHideDuration={6000}
        onClose={() => {
          setRequestAlert(false);
        }}
      >
        <Alert severity="success">
          Request Submitted - please wait while we review your request!
        </Alert>
      </Snackbar>
      <Snackbar
        open={earningsAlert}
        autoHideDuration={6000}
        onClose={() => {
          setEarningsAlert(false);
        }}
      >
        <Alert severity="error">
          Not Enough Earnings - You must have a minimum of $25 to request a
          withdrawal!
        </Alert>
      </Snackbar>
      <Snackbar
        open={emailVerificationAlert}
        autoHideDuration={6000}
        onClose={() => {
          setEmailVerificationAlert(false);
        }}
      >
        <Alert severity="success">
          Email sent to {user && user.email}. Please check your inbox.
        </Alert>
      </Snackbar>
      <Snackbar
        open={emailFailedAlert}
        autoHideDuration={6000}
        onClose={() => {
          setEmailFailedAlert(false);
        }}
      >
        <Alert severity="error">
          We could not send an email to {user && user.email}. Please contact
          support.
        </Alert>
      </Snackbar>
      <Snackbar
        open={linkCopiedAlert}
        autoHideDuration={6000}
        onClose={() => {
          setLinkCopiedAlert(false);
        }}
      >
        <Alert severity="success">Link copied to clipboard.</Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={processing}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Dashboard;
