import React, { useState, useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import "./SignUpInfo.module.css";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import { UserContext } from "../../../../providers/UserProvider";
import { db } from "../../../../firebase";
import { navigate } from "@reach/router";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const formStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  margin: {
    margin: theme.spacing(1),
    width: "100%"
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
    width: "100%"
  },
  formControl: {
    margin: theme.spacing(1)
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const platformNames = ["Twitch", "Youtube", "Facebook"];


const SignUpInfo = () => {
  const user = useContext(UserContext);
  const axios = require("axios");
  let countries2 = [""];

  const [state, setState] = useState({
    age: "",
    name: "",
    countries: []
  });

  axios
    .get("https://restcountries.eu/rest/v2/all")
    .then(function(response) {
      // handle success
      response.data.map(country => countries2.push(country.name));
      const USA = countries2.indexOf('United States of America');
    
      
      countries2.unshift(countries2.splice(USA, 1));
      countries2.unshift("");
      setState({
        countries: countries2
      });
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    })
    .then(function() {
      // always executed
    });

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  useEffect(() => {
    
        console.log("current country: " + country);
        //do the same for all autofilled fields

  });

  const [platforms, setPlatforms] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [updateFailedAlert, setUpdateFailedAlert] = useState(false);

  const classes = formStyles();
  const storeInformation = async () => {
    setProcessing(true);

   

    if (user && country === 'United States of America') {
      const userObject = {
        firstName,
        lastName,
        country,
        selectedPlatforms: platforms,
        completedInfo: true
      };
      db.ref(`creators/${user.uid}`)
        .update(userObject)
        .then(() => {
          navigate("/dashboard");
        })
        .catch(error => {
          console.log(error);
          setUpdateFailedAlert(true);
        })
        .finally(() => {
          setProcessing(false);
        });
    }
    else {
      console.log(country);
      alert('We currently only support content creators who are located in the United States of America but will reach out to you as soon as we expand to other countries!');
      window.location = 'https://www.clickmidas.com/contact';
    }
  };
  return (
    <div>
      <Grid
        spacing={2}
        direction="row"
        justify="center"
        alignItems="center"
        style={{ width: "300px", margin: "auto", marginTop:'4vh' }}
      >
        <form
          action=""
          onSubmit={e => {
            e.preventDefault();
            storeInformation();
          }}
          defa
        >
          <Grid item xs={12}>
            <FormControl
              required
              className={clsx(classes.margin, classes.textField)}
              variant="filled"
            >
              <InputLabel htmlFor="filled-adornment-text" >
                First Name
              </InputLabel>
              <FilledInput
                id="filled-adornment-text"
                type="text"
                onChange={e => {
                  setFirstName(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              required
              className={clsx(classes.margin, classes.textField)}
              variant="filled"
            >
              <InputLabel htmlFor="filled-adornment-text">Last Name</InputLabel>
              <FilledInput
                id="filled-adornment-text"
                type="text"
                required={true}
                onChange={e => {
                  setLastName(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: "100%" }}
            >
              <InputLabel htmlFor="filled-age-native-simple">
                Country
              </InputLabel>
              <Select

                native
                onChange={ e => {
                    setCountry(e.target.value);
                }}
                textAlignLast="center"
              >
                {state.countries.map(country => (
                  <option value={country}>{country}</option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              required
              className={classes.formControl}
              style={{ width: "100%" }}
              color="secondary"
            >
              <InputLabel htmlFor="filled-age-native-simple">
                Platforms
              </InputLabel>
              <Select
              color='primary'
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={platforms}
                onChange={e => {
                  setPlatforms(e.target.value);
                }}
                input={<Input />}
                renderValue={selected => selected.join(", ")}
              >
                {platformNames.map(name => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                    color='primary'
                      checked={platforms.indexOf(name) > -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              size="large"
              variant="contained"
              style={{marginTop:'10px', backgroundColor:'rgb(113, 222, 140)', color:'#ffffff'}}
            >
              COMPLETE SIGN UP
            </Button>
          </Grid>
        </form>
      </Grid>
      <Backdrop className={classes.backdrop} open={processing}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={updateFailedAlert}
        autoHideDuration={6000}
        onClose={() => {
          setUpdateFailedAlert(false);
        }}
      >
        <Alert severity="error">
          We could not update your account information. Please contact support
          if problem persists.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SignUpInfo;
