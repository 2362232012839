import React from "react";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Grid from "@material-ui/core/Grid";
import { auth } from "../../../firebase";

const formStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: 300
  },
  forgot: {
    color: "#c5a24a",
    marginTop: theme.spacing(1),
    fontSize: "12px",
    "&:hover": {
      cursor: 'pointer'
    }
  }
}));

// determines whether the signin or signup elements are displayed
export default function SignIn() {
  // class SignIn extends Component {
  // constructor(props){
  //   super(props);
  //   this.login = this.login.bind(this);
  // };
  const classes = formStyles();
  const [values, setValues] = React.useState({
    email: "",
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
    checkedA: false,
    error: false,
    errorText: "Oops, there seems to be an error.",
    fpError: false,
    fpError2: false,
    fpErrorText: "Oops, there seems to be an error."
  });

  const login = e => {
    e.preventDefault();
    auth
      .signInWithEmailAndPassword(values.email, values.password)
      .catch(err => {
        console.log(err);
        let message = "";
        switch (err.code) {
          case "auth/network-request-failed":
            message = "Our servers are currently down, please try again later.";
            break;
          case "auth/user-not-found":
            message = "There is no account related to this email address.";
            break;
          case "auth/too-many-requests":
            message =
              "Too many login requests from this account. Please try again later.";
            break;
          case "auth/user-disabled":
            message =
              "This account has been disabled. Please contact admins for support.";
            break;
          default:
            message = "Invalid username or password.";
        }
        setValues({
          ...values,
          error: true,
          errorText: message
        });
      });
  };

  const handleChange = prop => event => {
    setValues({
      ...values,
      [prop]: event.target.value
    });
  };

  const handleCheckedChange = prop => event => {
    setValues({
      ...values,
      [prop]: event.target.checked
    });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
    console.log(event);
  };

  const handleForgotPassword = () => {
    if (!values.email){
      setValues({
        ...values,
        fpError: true,
        fpError2: true,
        fpErrorText: "Please enter the email associated with your Click Midas account."
      });
      return;
    }

    auth.sendPasswordResetEmail(values.email).then(function() {
      setValues({
        ...values,
        fpError2: true,
        fpErrorText: "Please check your email for instructions on how to reset your password."
      });
    }).catch(function(error) {
      // An error happened.
    });
  }

  return (
    <div className="signIn">
      <div className="sign-in-info-container">
        <Grid container direction="row" justify="center" alignItems="center">
          <form>
            <Grid item xs={12}>
              <FormControl
                required
                className={clsx(classes.margin, classes.textField)}
                variant="filled"
                error={values.fpError}
              >
                <InputLabel htmlFor="filled-adornment-email">
                  Email
                </InputLabel>
                <FilledInput
                  onChange={handleChange("email")}
                  id="filled-adornment-email"
                  type="email"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                required
                className={clsx(classes.margin, classes.textField)}
                variant="filled"
              >
                <InputLabel htmlFor="filled-adornment-password">
                  Password
                </InputLabel>
                <FilledInput
                  id="filled-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  error={values.error}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormHelperText hidden={!values.error} error={values.error}>
                  {values.errorText}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.checkedA}
                    onChange={handleCheckedChange("checkedA")}
                    value="checkedA"
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                  />
                }
                label="Remember Me"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="secondary"
                onClick={login}
              >
                LOGIN
              </Button>
            </Grid>
            <Grid item xs={12}>
              <label
                onClick={handleForgotPassword}
                className={classes.forgot}
                error={values.fpError}
              >
              Forgot Password?
              </label>
              <FormHelperText hidden={!values.fpError2} error={values.fpError}>
                  {values.fpErrorText}
                </FormHelperText>
            </Grid>
          </form>
        </Grid>
      </div>
    </div>
  );
}
