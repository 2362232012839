import React, { useContext, useEffect, useState } from "react";
import Midas from "../Midas/Midas.component";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from "../../providers/UserProvider";
import { auth } from "./../../firebase";
import { navigate } from "@reach/router";


import './Header.module.css';

const style = {
  backgroundColor: "#111111",
  height: "70px"
};

const APP_URL =
process.env.REACT_APP_DEV_ENV === "production" ? `https://app.clickmidas.com` : `https://staging-clickmidas.web.app`; 

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(3),
    color: "white"
  },
  margin: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0)
  },
  title: {
    flexGrow: 1
  }
}));

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const user = useContext(UserContext);
  const [signedIn, setSignedIn] = useState(null);

  useEffect(() => {
    if (user) {
      setSignedIn(true);
    } else {
      setSignedIn(false);
    }
  }, [user]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppBar position="static" style={style}>
      <Toolbar>
        <Grid item xs={10} sm={6} style={{ textAlign: "left" }}>
          <Midas
            className={classes.title}
            height="50px"
            width="190.69px"
            style={{
              marginTop: "10px",
              cursor:'pointer'
            }}
          ></Midas>
        </Grid>
        <Hidden only={["lg", "md", "xl", "sm"]}>
          <Grid item xs={2}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => {handleClose()}}
            >
              <MenuItem onClick={() => {handleClose()}} hidden={!signedIn}>
                Logout
              </MenuItem>
              <MenuItem onClick={() => {handleClose()}} hidden={signedIn}>
                About
              </MenuItem>
              <MenuItem onClick={() => {handleClose()}} hidden={signedIn}>
                Advertise
              </MenuItem>
            </Menu>
          </Grid>
        </Hidden>
        <Hidden only={"xs"}>
          <Grid item sm={6} style={{ textAlign: "right" }}>
            <Button
                size="large"
                variant="outlined"
                color="primary"
                href={`${APP_URL}/admin-dash`}
                style={{
                  color: "white",
                  backgroundColor: "red"
                }}
                className={classes.margin}
                hidden={user ? !user.token.claims.admin : true}
              >
                Admin
              </Button>
            <Button
              target="_blank"
              href="https://www.clickmidas.com/advertisers"
              size="large"
              variant="outlined"
              color="primary"
              className={classes.margin}
              hidden={signedIn}
            >
              Advertise
            </Button>
            <Button
              size="large"
              variant="outlined"
              color="primary"
              href="https://www.clickmidas.com/contact"
              style={{
                color: "white",
                backgroundColor: "#71DE8C"
              }}
              className={classes.margin}
              hidden={!signedIn}
            >
              Contact Support
            </Button>
            <Button
              size="large"
              variant="outlined"
              color="primary"
              style={{
                color: "white"
              }}
              className={classes.margin}
              hidden={!signedIn}
              onClick={() => {
                auth.signOut();
                navigate("/");
              }}
            >
              Logout
            </Button>
            <Button
              href="https://www.clickmidas.com/"
              target='_blank'
              size="large"
              variant="contained"
              className={classes.margin}
              hidden={signedIn}
            >
              About
            </Button>
          </Grid>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
