import React, {useEffect, useState, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { db } from "../../firebase";
import Button from "@material-ui/core/Button";
import firebaseConfig from '../../config/firebaseConfig';
import axios from 'axios';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import {UserContext} from '../../providers/UserProvider';
import { navigate } from '@reach/router';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Admin() {
  const classes = useStyles();
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [payoutError, setPayoutError] = useState(false)
  const [userAdmin, setUserAdmin] = useState(false)
  const user = useContext(UserContext)
      useEffect(()=>{
         if(user){
             let claims = user.token && user.token.claims ? user.token.claims : {}
             if(claims && claims.admin){
                 fetchData()
                 setUserAdmin(true)
             }
             else {
                 navigate('/dashboard')
             }
         }
     },[user])

    const fetchData = async () => {
        db.ref('payoutRequests').once('value').then(res => {
            setRows([])
            let requests = []
            if(res.val()){
                let values = Object.keys(res.val())
                console.log('res', res.val(), values)
                values.forEach(async (id) => {
                    setLoading(true)
                    let earningRef = await db.ref(`earnings/${id}`).once('value')
                    let userRef = await db.ref(`creators/${id}`).once('value')
                    console.log('earnin', earningRef)
                    if(earningRef && userRef){
                        let userEarnings = earningRef.val()
                        let creator = userRef.val()
                        let data = {
                            userId: id || 'null',
                            username: creator.username || '',
                            latest: (userEarnings.latest / 100).toFixed(2) || 0,
                            paypalId: creator.paypalId || null
                        }
                        requests.push(data)
                        setRows(requests)
                        setLoading(false)
                    }
                })
            }
            else {
                setLoading(false)
            }
        })
    }
    const payCreator = async (row) =>{
        console.log('row', row)
        setLoading(true)
        await axios
        .post(
            `${firebaseConfig.functionURL}/triggerPayout`,
            { paypalId: row.paypalId, uid: row.userId },
            {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            }
        )
        .then(res => {
            res = res.data;
            setLoading(false)
            setPayoutError({show: true, message: 'Payout success', type:'success'})
            fetchData()
        }).catch(err => {
            console.log('err', err.response)
            err = err.response
            if(err && err.data && typeof(err.data) == 'object'  ){
                setPayoutError({show: true, message: 'Paypal error',type:'error'})
            }
            else if (err && err.data) {
                setPayoutError({show: true, message: err.response.data || '',type:'error'})
            }
            else {
                setPayoutError({show: true, message: 'Something went wrong', type:'error'})
            }
            setLoading(false)
        })
    }
        return (
          <div style={{ display: "flex", justifyContent:'center' }}>
              <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
              </Backdrop>
              {userAdmin && <Grid item xs={10} style={{ textAlign: "center", marginTop:'2vh' }}>
                  <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="simple table">
                          <TableHead>
                          <TableRow>
                              <TableCell>UserId</TableCell>
                              <TableCell align="right">Username</TableCell>
                              <TableCell align="right">Earnings ($)</TableCell>
                              <TableCell align="right">Actions</TableCell>
                          </TableRow>
                          </TableHead>
                          <TableBody>
                          {rows.map((row) => (
                              <TableRow key={row.userId}>
                              <TableCell component="th" scope="row">
                                  {row.userId}
                              </TableCell>
                              <TableCell align="right">{row.username}</TableCell>
                              <TableCell align="right">{row.latest}</TableCell>
                              <TableCell align="right">
                                  <Button
                                      size="small"
                                      variant="contained"
                                      style={{
                                      backgroundColor: "#71DE8C",
                                      color: "white",
                                      marginTop: "10px"
                                      }}
                                      onClick = {() => {payCreator(row)}}
                                  >
                                      Pay
                                  </Button>
      
                              </TableCell>
                              </TableRow>
                          ))}
                          </TableBody>
                      </Table>
                  </TableContainer>
              </Grid>}
              <Snackbar
                      open={payoutError.show}
                      autoHideDuration={6000}
                      onClose={() => {
                          setPayoutError({show:false,message:'',type:'error'});
                      }}
                  >
                      <Alert severity={payoutError.type}>
                      {payoutError.type === 'error' ? "Error" : "Success" } : {payoutError.message}
                      </Alert>
                  </Snackbar>
          </div>
        );
}
