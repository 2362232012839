import React, { useState, useEffect } from "react";
import { auth } from "../../../firebase";

const SkyscraperAd = props => {
  const [uid, setUid] = useState('nouser')
  // let width = props.w;
  // let height = props.h;

  useEffect(() => {
    auth.onAuthStateChanged(async userAuth => {
      if (userAuth && userAuth.uid ) {
        setUid(userAuth.uid)
      }
    });
  }, [])

  return (
    <div className="skyscraperAd" style={props.style}>
      {
        uid && props && props.channel && props.channel.uid &&
        <div data-aa-targeting={`{"streamer_id":"${props.channel.uid.toLowerCase()}", "anonymous_user_id": "${uid}"}`}  data-aaad={"true"} data-aa-adunit={"/196695463/CLKM_300v_1"}></div>
      }
    </div>
  );
};

export default SkyscraperAd;
