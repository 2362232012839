import React, { createContext, useContext, useState, useEffect } from "react";
import { db } from "../firebase";
import { UserContext } from "./UserProvider";

export const CreatorContext = createContext();
const CreatorProvider = ({ children }) => {
  const user = useContext(UserContext);
  const [creator, setCreator] = useState([]);
  useEffect(() => {
    if (user && user.uid) {
      const unsubscribe = db
        .ref(`creators/${user.uid}`)
        .on("value", function(snapshot) {
          if (snapshot) {
            setCreator(snapshot.val());
          }
        });
      return () => {
        unsubscribe();
      };
    }
  }, [user]);
  return (
    <CreatorContext.Provider value={creator}>
      {children}
    </CreatorContext.Provider>
  );
};

export default CreatorProvider;
