import React, { useState } from "react";
import ReactTwitchEmbedVideo from "react-twitch-embed-video";
import { db } from "../../firebase";




const Twitch = async ({ magicLink }) => {

  let [channel, setChannel] = useState(null);
  db.ref(`links/${magicLink}`)
    .once("value")
    .then(snapshot => {
      const streamInfo = snapshot.val();
      if (streamInfo.channel) {
        setChannel(streamInfo.channel);
      }
    });


      return (
        <div>
          <h1>twitch streaming</h1>
          {channel && <ReactTwitchEmbedVideo channel={channel} />}
        </div>
      );

};

export default Twitch;
