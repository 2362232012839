import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import paypalConfig from "../../config/paypalConfig";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
    backgroundColor: "#111111"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ handleClose, open }) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Set up your payment account
            </Typography>
          </Toolbar>
        </AppBar>
        <div
          style={{
            height: "100%",
            margin: "auto",
            display: "flex",
            alignItems: "center"
          }}
        >
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#006FBA",
              color: "white"
            }}
            onClick={() => {
              window.open(
                `${paypalConfig.paypalUrl}/connect?flowEntry=static&client_id=${
                  paypalConfig.paypalClientId
                }&scope=email https://uri.paypal.com/services/paypalattributes &redirect_uri=${encodeURIComponent(
                  paypalConfig.paypalRedirectUri
                )}`,
                "_self"
              );
            }}
          >
            Connect with PayPal
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
