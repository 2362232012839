import React, { useState } from "react";
import { db } from "../../firebase";


const Youtube = ({ magicLink }) => {

  let [channel, setChannel] = useState(null);
  db.ref(`links/${magicLink}`)
    .once("value")
    .then(snapshot => {
      console.log(snapshot.val());
      const streamInfo = snapshot.val();
      if (streamInfo.channel) {
        setChannel(streamInfo.channel);
      }
    });
  return (
    <div>
      <h1>youtube streaming</h1>
      {/* {channel && <ReactTwitchEmbedVideo channel={channel} />} */}
      <p>
        We need to get the channel id of the youtube user and display a stream
        like this
      </p>
      <h2>https://www.youtube.com/embed/live_stream?channel=Channel-ID</h2>
      {channel && (
        <iframe
          width="560"
          height="315"
          title="youtube stream"
          src={`https://www.youtube.com/embed/live_stream?channel=${channel}`}
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen="false"
        ></iframe>
      )}
    </div>
  );
};

export default Youtube;
