import React, { useEffect, useState } from "react";
import Embed from "../../components/Embed/EmbedTwitch/Embed.component";


import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import SkyscraperAd from "../../components/Ads/BannerAd/SkyscraperAd.component";
import BannerAd from "../../components/Ads/BannerAd/BannerAd.component";
import VideoAd from "../../components/Ads/BannerAd/VideoAd.component";
import "./StreamPage.module.css";
// import { CreatorContext } from "../../providers/CreatorProvider";
import { auth } from "../../firebase";
// import firebaseConfig from "../../config/firebaseConfig";
// import axios from 'axios'

// import AdBlockAlert from "../../components/ad-block-detector/ad-block-detector.component";
import adBlockDetector from "../..//utilities/utilities";
import firebaseConfig from "../../config/firebaseConfig";
import axios from "axios";








const stream = (props, adBlockBool) => {

  //uncomment for audience tracker
  
  let user = {};

  setTimeout(() => {
    user = auth.currentUser;
  }, 1000)

  const [channel, setChannel] = useState({});

  // set to true to enable video ads, set to false to disable video ads
  const [displayVideo, setDisplayVideo] = useState(false);
  const [displayAd, setDisplayAd] = useState(true);

  // set to true to disable supportbtn
  const [enableSupportBTN, setEnableSupportBTN] = useState(false);

  // set to true to enable coin tracker
  const trackCoins = true;


  const [adBlock, setAdblock] = useState();

  
  console.log("props", props);

  useEffect(() => {

    if(props.uid === "dehmuffinman"){
      setDisplayAd(false);
    }

    auth.onAuthStateChanged(async userAuth => {
      if (userAuth && userAuth.uid && !userAuth.isAnonymous) {
      } else if (userAuth && userAuth.uid && userAuth.isAnonymous) {
      } else {
   
        auth.signInAnonymously().then(async auth => {
        });
      }
    });

  },[])

  useEffect(() => {
    let isSubscribed = true;

    let video = null;
    let script = null;
    const targetNode = document.getElementById('videoAD');
    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = function(mutationsList, observer) {
        // Use traditional 'for loops' for IE 11
        for(const mutation of mutationsList) {
            if (mutation.type === 'childList') {
                let totalNodes = mutation.addedNodes.length;
                if(totalNodes === 0 && isSubscribed) {
                  setDisplayVideo(false);
                  setEnableSupportBTN(true);
                }
            }
        }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
   


        setInterval(() => {
          video = document.getElementById('AVplayer0');
          script = document.getElementById("AVLoadervdplayer_vd631842051");

          let w = window.aniplayerPos;

          if(video) 
            { console.log("video: ", video)} 
          else 
            { 
              setDisplayVideo(false)
              
              if(script){
                script.remove();
                }
              
              if(w){
                delete(window.aniplayerPos);
              }
            }

          // if(!video && script){
          //   document.removeChild(script);
          // }

          observer.observe(targetNode, config);
          

          
            

            

          
        }, 5000)



   


        // setTimeout(() => {
          
        //   setDisplayVideo(true);
          
            

            

          
        // }, 1000)

        setInterval(() => {
        if(!document.hidden){
          if(!adBlock) {
            if (trackCoins){
                isLive();
              }
            }
          }
        }, 300000)
    
    
    return () => isSubscribed = false;
    
  }, [])

  async function isLive() {

          await axios.post(
              `${firebaseConfig.functionURL}/isLive`,
              { username: props.uid.toLowerCase()},
              {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              }
            )
            .then((res) => {
              if(res.data.is_live){
                TrackCoins(res.data.game_id);
              }
            }).catch(err => {
              console.log("isLive failed bc: " + err);
            });
        };

  async function UpdateCoins(user, creator,gameID) {
    await axios.post(
      `${firebaseConfig.functionURL}/trackCoins`,
        { viewer: user, creator: creator, gameID: gameID },
        {
          headers: {
            "Access-Control-Allow-Origin": "*"
          }
        }
    );

  }

  

  function TrackCoins(gameID) {

    if (user) {

      UpdateCoins(user.uid, props.uid, gameID);
      
  } else {

      UpdateCoins("1g2WvMid9EdZrZIaDfDFY5KS2sm1", props.uid, gameID);
  }
  }

  useEffect(() => {
    // let embed = document.getElementById('embed')
    // get URL params for stream player type and id
    // Set up Anonymous Auth if No user

    setChannel({
      uid: props.uid,
      type: props.channel,
    });
    
    // if (embed.clientWidth < embed.clientHeight) {
    //   setDimensions({
    //     height: embed.clientWidth / 1.75 ,
    //     width: embed.clientWidth
    //   });
    // }
    // else {
    //   setDimensions({
    //     height: embed.clientHeight - 55,
    //     width: embed.clientHeight * 1.75 -55,
    //     maxWidth: '100%'
    //   });
    // }

  }, [props])

  // const signIn = async (user) => {
    // await axios
    //   .post(
    //     `${firebaseConfig.functionURL}/initializeGam`,
    //     { uid: user.uid, streamerId: props.uid },
    //     {
    //       headers: {
    //         "Access-Control-Allow-Origin": "*"
    //       }
    //     }
    //   )
    //   .then(res => {
    //     res = res.data;
    //     localStorage.setItem(
    //       "customTargetingKeyId",
    //       res["customTargetingKeyId"]
    //     );
    //     setKey(res["customTargetingKeyId"]);
    //   });
  // }

  let isBlocked;

  async function detectAdBlock() {
      isBlocked = await adBlockDetector();
      setAdblock(isBlocked);


      console.log("isBlocked: " + isBlocked);
      if (isBlocked){
        alert("Please disable your adBlocker or whitelist App.ClickMidas.com and then click OK.");
      }
      // isBlocked ? <AdBlockAlert /> : (

  }

  detectAdBlock();




  const handleSupportClick = () => {
    
    setDisplayVideo(true);

    
    // setEnableSupportBTN(true);
  }

  // { displayVideo ? <div style={{paddingLeft: "20vw"}}><VideoAd displayVideo={displayVideo}/> </div>:}

        return (

          <div className="streamPage">
            <Box
              width="100%"
              height="100%"
              style={{
                margin: "",
                paddingBottom: "100px"
              }}
            >
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                {channel && channel.uid && (
                  <Grid item md={12}>
                    <h2 style={{ color: "white", paddingLeft: "3vw", width:'100%', textAlign: 'left' }}>
                      You're Supporting {channel.uid}!
                    </h2>
                  </Grid>
                )}
                <Hidden mdUp>
                  <Grid item md={12}>
                    <BannerAd
                      style={{
                        marginBottom: "1vw",
                        height: "15vh",
                        width: "100%"
                      }}
                    ></BannerAd>
                  </Grid>
                </Hidden>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={9}
                  style={  
                  {
                    minHeight: "65vh",
                    padding: ".2vw 3vw"
                  }
                  }
                >
                  <div id="videoAD">
                      {channel && (
                        <div
                          id={"embed"}
                          style={{
                            height: "60vh",
                            width: "100%",
                            display: "flex"
                          }}
                        >
                        
                          <Embed
                            style={{
                              height: `60vh`,
                              width: `80%`,
                              maxWidth: "100%"
                            }}
                            channel={channel}
                          />
                          </div>
                        )
                          }
                          </div>  
                  {/* <iframe
                    title={"fb"}
                    src={
                      "https%3A%2F%2Fwww.facebook.com%2F388261198325900%2Fvideos%2F2515545302070946%2F&width=640"
                    }
                    width={"640"}
                    height={"480"}
                    // style={{ border: "none", overflow: "hidden", height: "60vh" }}
                    scrolling={"no"}
                    frameborder={"0"}
                    allowTransparency={"true"}
                    allowFullScreen={"false"}
                  ></iframe> */}

                  <Grid
                    item
                    md={12}
                    style={{
                      marginTop: "1vw",
                      marginBottom: "2vw",
                      display: "flex"
                    }}
                  >
                    {channel && (
                      <BannerAd
                        style={{
                          minHeight: "15vh",
                          minWidth: "85%",
                          display: "flex"
                        }}
                        channel={channel}
                      ></BannerAd>
                    )}
                    ) 
                    <Hidden mdDown>
                      <div
                        hidden={displayAd}
                        onClick={handleSupportClick}
                          style={{
                            padding: "0 10px",
                            background:
                              "linear-gradient(90deg, #16D5D6 0%, #6EED8E 100%)",
                            height: "20vh",
                            cursor: enableSupportBTN ? "default" : "pointer",
                            width: "12vw",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            justifyContent: "center", 
                          }}
                          disable={enableSupportBTN}
                      >
                        <h4
                          style={{
                            paddingTop: "10px",
                            color: "#202331",
                            fontFamily: "Montserrat",
                            fontWeight: "bold"
                          }}
                        >
                          SUPPORT THIS STREAM
                        </h4>
                        <h2
                          style={{
                            color: "#f0f0f0",
                            fontFamily: "Montserrat",
                            fontWeight: "bold"
                          }}
                        >
                          Watch an Ad
                        </h2>
                      </div>
                    </Hidden>
                  </Grid>
                </Grid>
                <Hidden smDown>
                  <Grid item md={3} style={{ alignSelf: "start", paddingTop: ".5vw" }}>
                    {channel && (
                      <SkyscraperAd
                        style={{
                          paddingRight: "1vw",
                          minWidth: "100%",
                          minHeight: "75vh"
                        }}
                        channel={channel}
                      />
                    )}
                    )
                  </Grid>
                  
                  
                </Hidden>

                
                
              </Grid>
              <Hidden mdDown>
                <Grid item md={12}>
                {channel && displayVideo && channel.uid &&
                  (<div>
                      <VideoAd streamerID={props.uid} displayVideo={displayVideo} date={Date.now()}/>
                    </div>)    
                  } 
                </Grid>
                </Hidden>
            </Box>
            
                
          </div>
        );





};

export default stream;


