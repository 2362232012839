import { createMuiTheme } from "@material-ui/core/styles";
export const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#c5a24a"
    },
    secondary: {
      main: "#efefef",
      contrastText: "#c5a24a"
    }
  },
  status: {
    danger: "orange"
  },
  overrides: {
    MuiButton: {
      hover: {
        main: "#c5a24a",
        contrastText: "#efefef"
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "0.7rem"
      }
    }
  }
});

export const buttonTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#F1EEE1"
    },
    secondary: {
      main: "#F1EEE1",
      contrastText: "#202331"
    }
  },
  shape: {
    width: "170px",
    height: "49px",
    borderRadius: "12px"
  },
  status: {
    danger: "orange"
  },
  spacing: 4,
  typography: {
    button: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 700
    }
  },
  overrides: {
    MuiButton: {
      borderRadius: "5px",

      hover: {
        main: "#c5a24a",
        contrastText: "#efefef"
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "0.7rem"
      }
    }
  }
});
