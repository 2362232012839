import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Admin from "../admin/Admin";
import Creators from "../streamer-list/streamer-list";
import { UserContext } from "../../providers/UserProvider";
import { navigate } from "@reach/router";

import { ThemeProvider } from "@material-ui/core/styles";
import { outerTheme } from "../../themes/presets";
import { TransitionGroup } from "react-transition-group";

import TokensReview from "../../components/tokens-review/tokens-review.component";

let tabIndex = 0;

const tabIndexChangeHandler = (event, value, index) => {
  tabIndex = value;
};

const stylesTabs = theme => ({
  default_tabStyle: {
    color: "#333333",
    backgroundColor: "#efefef"
  },

  active_tabStyle: {
    color: "#ffffff",
    backgroundColor: "#c5a24a"
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  }
}));

export default function FullWidthTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  

  const user = useContext(UserContext);
      useEffect(()=>{
         if(user){
             let claims = user.token && user.token.claims ? user.token.claims : {}
             if(!claims && !claims.admin){
                 navigate('/dashboard')
             }
         }
     },[user])

  const handleChange = (event, newValue) => {
    setValue(newValue);
    tabIndexChangeHandler("", newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  return (
    <div className={classes.root} style={{marginTop:"2vh"}}>
    <ThemeProvider theme={outerTheme}>
            <TransitionGroup>
            <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
              centered
            >
              <Tab label="Midas Creators" {...a11yProps(0)} />
              <Tab label="Requested Payouts" {...a11yProps(1)} />
              <Tab label="Audience Review" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
            alignCenter={true}
          >
            <TabPanel
              dir={theme.direction}
              className={
                tabIndex === 0 ? stylesTabs.active_tab : stylesTabs.default_tabStyle
              }
            >
                <Creators />
            </TabPanel>
            <TabPanel dir={theme.direction}>
                <Admin />
            </TabPanel>
            <TabPanel dir={theme.direction}>
                <TokensReview />
            </TabPanel>
          </SwipeableViews>
            </TransitionGroup>
            </ThemeProvider>
      
    </div>
  );
}
