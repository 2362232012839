const paypalConfig =
  process.env.REACT_APP_DEV_ENV === "production"
    ? {
        paypalClientId:
          "AaREzTAc5o45cY_alpo1GGJEIWVJAZEwjHHQlLa4_qqRMSyEe5ZhQpQhv-BNhrLTmi8M6L6Ad-HXGxQt",
        paypalBase64Id: "",
        paypalRedirectUri: "https://app.clickmidas.com/connect",
        paypalUrl: "https://www.paypal.com"
      }
    : {
        paypalClientId:
          "AaCkhyY2k-v0GShM_QC5-ORgRsv0ZK0ELNfPwfnXt0VeEDP_cKGc7C_LJniMdIyCw9y7-QYLs7-bU1xg",
        paypalBase64Id: "",
        paypalRedirectUri: "https://staging-clickmidas.web.app/connect",
        paypalUrl: "https://www.sandbox.paypal.com"
      };

export default paypalConfig;
