import React from "react";
import "./App.css";
import Auth from "./pages/authenticate/Auth";
import Dashboard from "./pages/dashboard/Dashboard";
import Stream from "./pages/StreamPage/StreamPage.component";
import Connect from "./pages/connect/Connect";
import { Router } from "@reach/router";
import UserProvider from "./providers/UserProvider";
import CreatorProvider from "./providers/CreatorProvider";
import Header from "./components/Header/Header.component";
import { ThemeProvider } from "@material-ui/core/styles";
import { buttonTheme } from "./themes/presets";
import Review from "./pages/review/Review";
import Twitch from "./pages/twitch/Twitch";
import AccountInfo from "./pages/account-info-page/AccountInfo";
import TwitchAuth from "./pages/twitch/Auth";
import InstagramAuth from "./pages/instagram/auth";
import Youtube from "./pages/youtube/Youtube";
import EmailVerification from "./pages/email-verification/EmailVerification";
import AdminDash from "./pages/admin-dashboard/admin-dashboard";
import Ads from "./pages/Ads.txt/Ads";


import {TransitionGroup} from 'react-transition-group'; // ES6



const App = () => {

  return (
    <div className="App">
      <UserProvider>
        <CreatorProvider>
          <div className="navBar">
            <ThemeProvider theme={buttonTheme}>
            <TransitionGroup>
                <Header />
            </TransitionGroup>
            </ThemeProvider>
          </div>
          <div
            className="component"
            style={{
              backgroundColor: window.location.href.includes('stream') ?
              "linear-gradient(90deg, #16D5D6 0%, #6EED8E 100%)" : "#E5E5E5",
              height: "100vh",
              paddingTop: "70px"
            }}
          >
            <Router>
              <Auth path="/" />
              <Dashboard path="/dashboard" />
              <Stream path="/stream/:channel/:uid" />
              <Connect path="/connect" />
              <TwitchAuth path="/auth/twitch" />
              <InstagramAuth path="/auth/instagram" />
              <Review path="/review" />
              <EmailVerification path="/email-verification" />
              <Twitch path="/twitch/:magicLink" />
              <Youtube path="/youtube/:magicLink" />
              <AccountInfo path="/account-info" />
              <AdminDash path="/admin-dash" />
              <Ads path="/ads.txt" />
            </Router>
          </div>
        </CreatorProvider>
      </UserProvider>
    </div>
  );
};

// actions storage made available

export default App;
