import React, { useContext, useState, useEffect } from "react";
import querystring from "querystring";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UserContext } from "../../providers/UserProvider";
import axios from "axios";
import firebaseConfig from "../../config/firebaseConfig";
import { navigate } from "@reach/router";
import { db } from "../../firebase";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: "10%",
    width: "100%",
    height: "100%",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  }
}));

const TwitchAuth = ({ location }) => {
  const user = useContext(UserContext);
  const classes = useStyles();
  const code = querystring.parse(location.search)["?code"];
  const [redirect, triggerRedirect] = useState(false);
  console.log("code", code);
  if (user && user.uid) {
    axios
      .post(
        `${firebaseConfig.functionURL}/retrieveAndStoreTwitchChannel`,
        {
          code
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*"
          }
        }
      )
      .then(async res => {
        console.log(res);
        await db
          .ref(`creators/${user.uid}`)
          .update({
            twitch: {
              ...res.data
            }
          })
          .catch(error => {
            console.log(error);
          });
        axios
          .post(
            `${firebaseConfig.functionURL}/generateLink`,
            { uid: user.uid, platform: "twitch" },
            {
              headers: {
                "Access-Control-Allow-Origin": "*"
              }
            }
          )
          .then(res => {
            const ref = String(res.data);
            const refArr = ref.split("/");
            const link = refArr[refArr.length - 1];
            navigator.clipboard.writeText(
              `https://staging-midas.web.app/twitch/${link}`
            );
            triggerRedirect(true);
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (redirect) {
      navigate("/dashboard");
    }
  }, [redirect]);


  return (
    <div className={classes.root}>
      <CircularProgress size="5rem" style={{ color: "#D1AB4C" }} />
      <h1 className="heading">
        Please wait while we request Twitch for your stream key...
      </h1>
    </div>
  );
};

export default TwitchAuth;
