import React, { useState, useEffect } from "react";
import ScriptTag from 'react-script-tag';
// import { auth } from "../../../firebase";


const VideoAd = props => {
  // const [uid, setUid] = useState('nouser')


  const [url, setUrl] = useState();
  
  
  useEffect(() => {


    
    
    
    // auth.onAuthStateChanged(async userAuth => {
    //     if (userAuth && userAuth.uid) {    
    //         setUid(userAuth.uid)
    //     }
    // });

     //username = username.toLowerCase();
    setUrl(`https://tags.viewdeos.com/midas/player-midas.js?AV_SUBID=${props.streamerID}`);



    
},[props])


 

  // const [uid, setUid] = useState('nouser');
  // // let width = props.w;
  // // let height = props.h;

  // useEffect(() => {
  //   let isSubscribed = true;
  //   console.log(props.streamerID);
  //   console.log(url);
  //   auth.onAuthStateChanged(async userAuth => {
  //     if (userAuth && userAuth.uid && isSubscribed) {
  //       setUid(userAuth.uid)
  //     }
  //   });

  //   return () => isSubscribed = false;
  // }, [])
  
  // useEffect(() => {
  //   const script = document.createElement('script');
    
  //   script.url = url;
  //   script.async = true;
  //   script.id = "vd631842051";

  //   document.body.appendChild(script);

  //   return() => {
  //       document.body.removeChild(script);
  //   }
  // }, [props])

  return (
    <div className="videoAd" 
    style={  
      {
        height: "35vh",
        width: "32vw",
        position: "fixed", 
        bottom: 0, 
        right: 0,
      }}
      >
      <h1 style={{
          color: "white",
          fontSize: "2.5vh",
          textAlign: "center",
        }}
        >
        This video supports {props.streamerID}
      </h1>
      {props.displayVideo && (<ScriptTag async id="vd631842051" src={url} />)}  
      
    </div>
  );
};

export default VideoAd;
