const firebaseConfig =

  process.env.REACT_APP_DEV_ENV === "production"
    ? {
        apiKey: "AIzaSyCzzLIMozi2vyhWaX0YJ61o9DKVamq6g-o",
        authDomain: "old-ninja-production.firebaseapp.com",
        databaseURL: "https://old-ninja-production.firebaseio.com",
        projectId: "old-ninja-production",
        functionURL:
          "https://us-central1-old-ninja-production.cloudfunctions.net",
        storageBucket: "old-ninja-production.appspot.com",
        messagingSenderId: "434645101716",
        appId: "1:434645101716:web:4912d4b4c32ea4b40fd881",
        measurementId: "G-DEW482ZK3M"
      }
    : 
      {
        apiKey: "AIzaSyDLoN10GUlG_gmXvyu0bb2xOPMLgP71YGY",
        authDomain: "old-ninja-staging.firebaseapp.com",
        databaseURL: "https://old-ninja-staging.firebaseio.com",
        projectId: "old-ninja-staging",
        //  functionURL: "http://localhost:5001/old-ninja-staging/us-central1",
        functionURL: "https://us-central1-old-ninja-staging.cloudfunctions.net",
        storageBucket: "old-ninja-staging.appspot.com",
        messagingSenderId: "21490447545",
        appId: "1:21490447545:web:88d2e91fcc37fe67239768",
        measurementId: "G-J0MPJ8G06D"
      };

      console.log('process env', process.env, firebaseConfig)
export default firebaseConfig;
