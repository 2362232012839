import React from 'react';
import logo from '../../assets/Images/Midas-coin-white-text.png'


const midas = (props) => {

  return(
    <div>
      <img
        onClick={() => {window.open('https://www.clickmidas.com/','_blank')}}
        src={logo}
        alt="MIDAS"
        height={props.height}
        width={props.width}
        style={props.style}>
        </img>
    </div>
  );
}

export default midas;
