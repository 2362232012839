// an admin accessible page only
// review, approve & trigger payouts
import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { db } from "../../firebase";
import { UserContext } from "../../providers/UserProvider";
import { Button } from "@material-ui/core";
import { navigate } from "@reach/router";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import AudienceReview from "./audience-review.component";




const APP_URL =
process.env.REACT_APP_DEV_ENV === "production" ? `https://app.clickmidas.com` : `https://staging-clickmidas.web.app`; 

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const formStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: 300
  },
  forgot: {
    color: "#c5a24a",
    marginTop: theme.spacing(1),
    fontSize: "12px",
    "&:hover": {
      cursor: 'pointer'
    }
  }
}));


 
  


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TokensReview = () => {
  const classes = useStyles();
  const formClasses = formStyles()
  const user = useContext(UserContext);
  const [midasCreators, setMidasCreators] = useState([]);
  const [midasAccounts, setMidasAccounts] = useState([]);
  const [creatorReview, setCreatorReview] = useState([]);
  const [username, setUserName] = useState([]);
  const [displayType, setDisplayType] = useState(true);

  const [linkCopiedAlert, setLinkCopiedAlert] = useState(false);
  const [noCreatorChosenAlert, setNoCreatorChosenAlert] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Enter Username or Email Address");
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [display, setDisplay] = useState([]);


  useEffect(() => {

    if (user) {

      if (!user.token.claims.admin) navigate("/dashboard");
      console.log(user.token.claims);
      (async () => {
         const creatorsSnapshot = await db.ref(`creators/`).once("value");
         const creators = creatorsSnapshot.val();
         const audienceSnapshot = await db.ref(`creatorsAudienceMinutes/`).once("value");
         const audience = audienceSnapshot.val();

        //const creatorsSnapshot = await db.ref(`creators/`).once("value");
        // const creators = creatorsSnapshot.val();
        const data = [];
        const accounts = [];

        let creatorObject = {};
        for (let key in creators) {

            
            if(creators[key].twitch && audience[key]){
            
              creatorObject = {
                username: creators[key].username,
                uid: key,
                twitch: creators[key].twitch.name,
                totalMinsWatched: (audience[key].totalMinutes) || 0,
                email: creators[key].email,
                audience: audience[key]
              };
              data.push(creatorObject);
              accounts.push({key: creatorObject})
          } else if (creators[key].twitch){
            creatorObject = {
              username: creators[key].username,
              uid: key,
              twitch: creators[key].twitch.name,
              totalMinsWatched: 0,
              email: creators[key].email
            };
            data.push(creatorObject);
            accounts.push({key: creatorObject})
          }            
        }
          
          setMidasAccounts(accounts);
          setMidasCreators(data);
          setDisplay(data);

      })();
    }
  }, [user]);

  const handleClick = () => {
    if(display === midasCreators){
      setNoCreatorChosenAlert(true)
    } else {
      setDisplay(midasCreators);
      setDisplayType(true);
    }
  }

  const handleCreatorClick = async (creator) => {
    const creatorsSnapshot = await db.ref(`creators/`).once("value");
    const creators = creatorsSnapshot.val();
    
    setUserName(creator.username);
    console.log("creator: " + creator.audience);
    const data = [];
    for (let viewer in creator.audience) {
        if (viewer !== "totalMinutes" && creators[viewer]){
            const watcher = {
                key: viewer,
                viewer: creator.audience[viewer]
            };
            console.log("viewer: " + watcher.key);
            console.log("minutes: " + watcher.viewer);
            data.push(watcher);
        }
    }

    
        setCreatorReview(data)
        setDisplayType(false)

        console.log("creator review: " + creatorReview);
        console.log("data: " + data);
        console.log("display: " + display);  
        
        window.scrollTo({ top: 0 });
  }

  const handleChange = () => event => {
    setSearchInput(event.target.value);

    const result = [];

    for(let creator in midasCreators){
      if(midasCreators[creator].email.includes(searchInput) || midasCreators[creator].username.includes(searchInput)){
        result.push(midasCreators[creator]);
        setError(false);
      }
      else if (searchInput === ""){
        setDisplay(midasCreators);
        setError(false);
        setErrorMsg("Enter Username or Email Address");
      }
      else {
        setError(true);
        setErrorMsg("Username or Email not found");
      }

      setSearchResults(result);

      if(searchResults.length > 0){
        setDisplay(searchResults);
      } else if (searchInput.length > 0){
        setDisplay([]);
      } else {
        setDisplay(midasCreators);
      }
    }
    


    }
  
  
  


  return (
    <div style={{
      
    }}>
            <div>
              <FormControl
                className={clsx(formClasses.margin, formClasses.textField)}
                variant="filled"
                error={error}
                hidden={displayType}
              >
                <InputLabel htmlFor="filled-adornment-email">
                  {errorMsg}
                </InputLabel>
                <FilledInput
                  onChange={handleChange("email")}
                  id="filled-adornment-email"
                  type="text"
                />
              </FormControl>
              <button style={{marginTop: "2.5vh"}} onClick={handleClick} hidden={displayType}>Change View</button>
            </div>
      {displayType  ? (
        <div>
        <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell >Username</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Twitch Username</TableCell>
              <TableCell align="center">Total Hours Watched</TableCell>
              <TableCell align="center">Midas Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { 
              display.length > 0 &&
              display.map(row => (
                <TableRow key={row.uid}>
                  <TableCell component="th" scope="row" onClick={() => handleCreatorClick(row)}>

                    {row.username}
                  </TableCell>
                  <TableCell align="center"
                      onClick={() => {

                        navigator.clipboard.writeText(
                          `${row.email}`
                        );
                        setLinkCopiedAlert(true);
                      }}
                      style={{
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer"
                      }}>
                        <b>{row.email}</b>
                      </TableCell>
                  <TableCell align="center">{row.twitch}</TableCell>
                  <TableCell align="center">{(row.totalMinsWatched / 60).toFixed(2)} hrs</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      style={{
                        margin: "0 1vh"
                      }}
                      onClick={() => {

                        navigator.clipboard.writeText(
                          `${APP_URL}/stream/twitch/${row.username}`
                        );
                        setLinkCopiedAlert(true);
                      }}
                    >
                      Copy Link

                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        margin: "0 1vh"
                      }}
                      onClick={() => {
                        window.open(`${APP_URL}/stream/twitch/${row.username}`, "_blank")
                      }
                    }
                    >
                      Open Link

                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        open={linkCopiedAlert}
        autoHideDuration={6000}
        onClose={() => {
          setLinkCopiedAlert(false);
        }}
      >
        <Alert severity="success">Link copied to clipboard.</Alert>
      </Snackbar>
      <Snackbar
        open={noCreatorChosenAlert}
        autoHideDuration={6000}
        onClose={() => {
          setNoCreatorChosenAlert(false);
        }}
      >
        <Alert severity="failure">Please click on a creator's username to view audience members watched.</Alert>
      </Snackbar>
      </div>
        
      ): (
          <div>
            <AudienceReview username={username} audience={creatorReview} creators={midasAccounts}/> 
          </div>
        )}
      
    </div>

  );
};

export default TokensReview;