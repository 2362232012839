// an admin accessible page only
// review, approve & trigger payouts
import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { UserContext } from "../../providers/UserProvider";

import { navigate } from "@reach/router";

import { db } from "../../firebase";








const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});



 
  




const AudienceReview = (props) => {
  const classes = useStyles();
  const user = useContext(UserContext);
  const midasCreators = useState(props.creators);
  const [audienceAccounts, setAudienceAccounts] = useState([]);
  const [display] = useState(props.audience);

  useEffect(() => {

    if (user) {

      if (!user.token.claims.admin) navigate("/dashboard");
      console.log(user.token.claims);
      (async () => {
        const creatorsSnapshot = await db.ref(`creators/`).once("value");
        const creators = creatorsSnapshot.val();

    console.log("audience review midas: " + midasCreators);
    console.log("audience review display: " + display);
    
    const data = [];

    for(let viewer in display) {
        const key = display[viewer].key;
        const time = display[viewer].viewer
        console.log("viewer key: " + key.key);
        console.log("time watched: " + time);

        let account = {};
        
        if(creators[key])
           {
               account = {
                    key: creators[key].userId,
                    midasName: creators[key].username,
                    twitchName: creators[key].twitch ? creators[key].twitch.name : "Twitch Account not linked",
                    email: creators[key].email ? creators[key].email : "No email on file",
                    minutes: time
                    }
    } else {
            account = {
                key: key,
                midasName: "Unknown User",
                twitchName: "Unknown User",
                email: "Unknown User",
                minutes: time
        }
    }
        data.push(account)
        console.log("account: " + account.midasName);
        
    }

    setAudienceAccounts(data);

      })();
    }
  }, [user]);


  
  
  
        return (
            <div >
                <div>
                <h1>{props.username}'s Audience Overview</h1>
                <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Midas Username</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">Twitch Username</TableCell>
                      <TableCell align="center">Total Hours Watching {props.username}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { 
                      audienceAccounts.length > 0 &&
                      audienceAccounts.map(row => (
                        <TableRow key={row.key}>
                          <TableCell component="th" scope="row">
        
                            {row.midasName}
                          </TableCell>
                          <TableCell align="center"
                              onClick={() => {
        
                                navigator.clipboard.writeText(
                                  `${row.email}`
                                );
                              }}
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                                cursor: "pointer"
                              }}>
                                <b>{row.email}</b>
                              </TableCell>   
                          <TableCell align="center">{row.twitchName}</TableCell>
                          <TableCell align="center">{(row.minutes / 60).toFixed(2)} hrs</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              </div> 
            </div>
          );
};

export default AudienceReview;






// useEffect(async () => {

//     if (user) {

//       if (!user.token.claims.admin) navigate("/dashboard");
//     }

//         const creatorsSnapshot = await db.ref(`creators/`).once("value");
//         const creators = creatorsSnapshot.val();

//     console.log("audience review midas: " + midasCreators);
//     console.log("audience review display: " + display);
    
//     const data = [];

//     for(let viewer in display){
//         const key = display[viewer].key
//         console.log("viewer key: " + key);

//         let account = {};
        
//         if(creators[key])
//            {
//                account = {
//                     key: creators[key].userId,
//                     midasName: creators[key].username,
//                     twitchName: creators[key].twitch ? creators[key].twitch.name : "Twitch Account not linked",
//                     email: creators[key].email,
//                     coins: display[viewer].viewer
//                     }
//     } else {
//             account = {
//                 key: key,
//                 midasName: "Unknown User",
//                 twitchName: "Unknown User",
//                 email: "Unknown User",
//                 coins: display[viewer].viewer
//         }
//     }
//         data.push(account)
//         console.log("account: " + account.midasName);
        
//     }

//     setAudienceAccounts(data);

  
// },)







