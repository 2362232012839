import React, { useState, useEffect } from "react";
import { auth } from "../../firebase";
import * as queryString from "query-string";
import { navigate } from "@reach/router";
import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import firebase from 'firebase';
// import axios from 'axios'
// import firebaseConfig from '../../config/firebaseConfig'
const EmailVerification = props => {
  const user = useContext(UserContext);
  const [verified, setVerified] = useState(false);
  const [notVerified, setNotVerified] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const queryParams = queryString.parse(props.location.search);
  const code = queryParams.oobCode;
  const type = queryParams.mode;

  const formStyles = makeStyles(theme => ({
    root: {
      display: "flex"
    },
    margin: {
      margin: theme.spacing(1)
    },
    withoutLabel: {
      marginTop: theme.spacing(3)
    },
    textField: {
      width: 300
    },
    reset: {
      margin: theme.spacing(3), 
      padding: "40px 75px 25px",
      borderRadius: "2%",
      background: 'white !important'
    },
    resetButton: {
        color: "#202331",
        backgroundColor: "#F1EEE1",
        marginTop: theme.spacing(1),
        "&:hover": {
          color: "#efefef",
          backgroundColor: "#c5a24a",
          
        }
    }
  }));
  const classes = formStyles();
  const [values, setValues] = React.useState({
    email: "",
    username: "",
    password: "",
    passwordConfirm: "",
    weight: "",
    weightRange: "",
    showPassword: false,
    checkedA: false,
    ageCheck: false,
    available: true,
    accountCreated: false,
    emailVerified: false
  });



  const handleEmailVerification = async (actionCode , mode) => {

    auth
      .applyActionCode(actionCode)
      .then(async res => {
        if (user && user.uid) {
          // await db
          //   .ref(`creators/${user.uid}/emailVerified`)
          //   .set(true)
          //   .catch(e => {
          //     console.error(e);
          //   });
          auth.onAuthStateChanged(async userAuth => {
            await userAuth.reload()
            navigate("/dashboard");
          })
        }
        else {
          window.location.reload(false);
          navigate("/dashboard");
        }
        setVerified(true);
      })
      .catch(error => {
        console.log("could not verify email address");
        console.error(error);
        setNotVerified(true);
      });

  };

  const handleResetPassword = async () => {
    // Localize the UI to the selected language as determined by the lang
    // parameter.

    if(values.password !== values.passwordConfirm){
      checkPasswords();
      return;
    }
    // Verify the password reset code is valid.
    await firebase.auth().confirmPasswordReset(code, values.password).then(function(resp) {
      navigate("/");
      console.log(resp);
    }).catch(function(error) {
      alert(error);
    });   
  }

  const handleChange = prop => event => {
    setValues({
      ...values,
      [prop]: event.target.value
    });
  };


  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
    console.log(event);
  };


  useEffect(() => {
    if (code && type) {
      (async () => {

        if(type === 'verifyEmail')
          {
            await handleEmailVerification(code, type);
          }
        else if(type === 'resetPassword') {
          setIsReset(true);
        }
      })();
    }
    else {
      // navigate("/dashboard");
    }
  }, []);




 
  const [errors, setErrors] = React.useState({
    errorUN: false,
    errorTextUN: "Username is already in use by another account.",
    errorEmail: false,
    errorTextEmail: "Invalid email / email is already in use.",
    errorPW: false,
    errorTextPW: "Passwords do not match.",
    errorUNPattern: false,
    errorTextUNPattern: 'Username must contain only alphanumeric values'
  });


  const checkPasswords = () => {
    if (values["password"] && values["passwordConfirm"]) {
      if (
        values.password === values.passwordConfirm
      ) {
        setErrors({
          ...errors,
          errorPW: false
        });
      } else {
        setErrors({
          ...errors,
          errorPW: true
        });
      }
    } else {
      setErrors({
        ...errors,
        errorPW: false
      });
    }
  };
















  if (verified) {
    console.log("verified");
    navigate("/dashboard", { replace: true });
  }
  if (notVerified) {
    console.log("not verified");
    navigate("/dashboard");
  }
  if (isReset) {
    return (
      <div className="signIn">
      <div className="sign-in-info-container">
        <Grid container direction="row" justify="center" alignItems="center">
          <form 
            className={classes.reset}
            onSubmit={handleResetPassword}>
            <Grid item xs={12}>
                <label>Please enter your new password</label>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                required
                className={clsx(classes.margin, classes.textField)}
                variant="filled"
              >
                <InputLabel htmlFor="filled-adornment-password">
                  Password
                </InputLabel>
                <FilledInput
                  id="filled-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      {" "}
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                required
                error={errors.errorPW}
                className={clsx(classes.margin, classes.textField)}
                variant="filled"
              >
                <InputLabel htmlFor="filled-adornment-password">
                  Confirm Password
                </InputLabel>
                <FilledInput
                  id="filled-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.passwordConfirm}
                  onChange={handleChange("passwordConfirm")}
                  endAdornment={
                    <InputAdornment position="end">
                      {" "}
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText
                  hidden={!errors.errorPW}
                  error={errors.errorPW}
                >
                  {errors.errorTextPW}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                className={classes.resetButton}
                onClick={handleResetPassword}
              >
                UPDATE PASSWORD
              </Button>
            </Grid>
          </form>
        </Grid>
      </div>
    </div>
      )
  }
  return <h3 style={{ marginTop: "30px" }}>Verifying email address...</h3>;
};

export default EmailVerification;
