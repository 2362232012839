import React, { useState, useEffect } from 'react';
import { auth } from "../../../firebase";


// const handleUrlUpdate = () => {
//     setValues({
//       ...values,
//       image: !values.showPassword
//     });

//   };

const BannerAd = (props) => {
    const [uid, setUid] = useState('nouser')
    // let sourceUrl = 'https://loremflickr.com/'
    // let width = props.w;
    // let height = props.h;
    useEffect(() => {
        console.log('Banner Props', props)
        auth.onAuthStateChanged(async userAuth => {
            if (userAuth && userAuth.uid) {    
                setUid(userAuth.uid)
            }
        });
    },[props])

    setTimeout(() => {
        document.dispatchEvent(new CustomEvent("aaw", { "command": "processAdsOnPage" }));
        console.log('firing event')
    },2000)
    return(
        
        <div className="bannerAd" style={props.style} >
            {   uid && props && props.channel && props.channel.uid &&
                <div data-aa-targeting={`{"streamer_id":"${props.channel.uid.toLowerCase()}", "anonymous_user_id": "${uid}"}`} data-aaad={'true'} data-aa-adunit={'/196695463/CLKM_728v_1'}></div>
            }
            
        </div>

    )
};

/*
// commenting this out for when we transition to Google Adsense
class Ad extends React.Component{
    componentDidMount () {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    render () {
        return (
            <div className='ad'>
                <ins className='adsbygoogle'
                style={{ display: 'block' }}
                data-ad-client='ca-pub-xxxxxxxxxx'
                data-ad-slot='xxxxxxxxxx'
                data-ad-format='auto' />
            </div>
        )
    }
};
*/

export default BannerAd;
