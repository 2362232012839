import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import querystring from "querystring";
import { navigate } from "@reach/router";
import { db } from "../../firebase";
import { UserContext } from "../../providers/UserProvider";
import firebaseConfig from "../../config/firebaseConfig";
import paypalConfig from "../../config/paypalConfig"
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: "10%",
    width: "100%",
    height: "100%",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  }
}));

const Connect = ({ location }) => {
  const user = useContext(UserContext);
  const code = querystring.parse(location.search)["?code"];
  const classes = useStyles();
  const paypalCredentials = paypalConfig.paypalBase64Id;
  const [redirect, triggerRedirect] = useState(false);
  const [callInProgress, setcallInProgress] = useState(false);
  useEffect(() => {
    if (user && user.uid && !callInProgress && code) {
      setcallInProgress(true)
      axios
        .post(
          `${firebaseConfig.functionURL}/getPaypalId`,
          {
            code,
            paypalCredentials
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*"
            }
          }
        )
        .then(async paypalId => {
          await db
            .ref(`creators/${user.uid}`)
            .update({ paypalId: paypalId.data })
            .catch(error => {
              console.log(error);
            });
            setcallInProgress(false)
          triggerRedirect(true);
        })
        .catch(error => {
          setcallInProgress(false)
          console.log(error);
          triggerRedirect(true);
        });
    }
  }, [code, user]);
  useEffect(() => {
    if (redirect) navigate("/dashboard");
  }, [redirect]);
  return (
    <div className={classes.root}>
      <CircularProgress size="5rem" style={{ color: "#D1AB4C" }} />
      <h1 className="heading">
        Please wait. This page will redirect automatically.
      </h1>
    </div>
  );
};

export default Connect;
