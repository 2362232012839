import React, { useEffect, useState, useContext } from "react";
import './Embed.module.css';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios'
import firebase from '../../../config/firebaseConfig'
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UserContext } from "../../../providers/UserProvider";
import ReactTwitchEmbedVideo from "react-twitch-embed-video";





// const APP_URL = process.env.REACT_APP_HOST_ENV === "production" ? 'app.clickmidas.com' : 'staging-clickmidas.web.app'
// const EMBED_URL = "https://embed.twitch.tv/embed/v1.js";


const useStyles = makeStyles(theme => ({
  embed: {
    height: "600px"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));


const Twitch = (props) => {
  const classes = useStyles()

  const [src, setSrc] = useState("");
  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    let channel = props.props.channel.type || "";
    let username = props.props.channel.uid.toLowerCase() || "";
    setProcessing(true);
    axios
      .post(`${firebase.functionURL}/getStreamLink`, { channel, username },
      {
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      })
      .then(res => {
        let channelID = res.data.channel;
        setSrc(
          // `https://player.twitch.tv/?channel=${channelID}&parent=${APP_URL}`
          `${channelID}`
        );
        setProcessing(false);
      })
      .catch(err => {
        setProcessing(false);
        console.log("err", err);
      });
  }, []);

  return (
    <div  style={{ width: "100%", display: "flex" }}>

      <Backdrop className={classes.backdrop} open={processing}>
          <CircularProgress color="inherit" />
        </Backdrop>
      {/* <iframe
        title={"twitch"}
        src={src}
        style={props.props.style}
        height={"60vh"}
        frameborder={"0"}
        scrolling={"no"}
        allowfullscreen={"false"}
      ></iframe> */}
      <ReactTwitchEmbedVideo allowpopups="false" channel={src} allowfullscreen="false" height= "100%" width= "100%" max-width= "100%" theme="dark"/>
    </div>
  );
}
const Youtube = props => {
  const classes = useStyles();
  const [src, setSrc] = useState("");
  const [processing, setProcessing] = useState(true);
  console.log("props", props);
  useEffect(() => {
    let channel = props.props.channel.type || "";
    let username = props.props.channel.uid || "";
    setProcessing(true);
    axios
      .post(`${firebase.functionURL}/getStreamLink`, { channel, username },
      {
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      })
      .then(res => {
        let channelID = res.data.channel;
        setSrc(
          `https://www.youtube.com/embed/live_stream?channel=${channelID}`
        );
        setProcessing(false);
      })
      .catch(err => {
        setProcessing(false);
        console.log("err", err);
      });
  }, []);

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <Backdrop className={classes.backdrop} open={processing}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <iframe
        title={"yt"}
        style={props.props.style}
        height={"60vh"}
        src={src}
        frameborder={"0"}
        allow={
          "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        }
        allowfullscreen={false}
      ></iframe>
    </div>
  );
};

const Facebook = props => {
  const classes = useStyles();
  const [src, setSrc] = useState('')
  const [processing, setProcessing] = useState(true);
  useEffect(() => {
    let channel = props.props.channel.type || '';
    let username = props.props.channel.uid || "";
    setProcessing(true)
    axios.post(`${firebase.functionURL}/getFbLink`, { channel, username },{
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      })
      .then(res => {
      let videos = res.data.data
      if (videos && videos[0]) {
        let liveVideo = videos[0]
        var doc = new DOMParser().parseFromString(
          liveVideo.embed_html,
          "text/html"
        );
        var links = doc.querySelectorAll("iframe");
        links[0] ? setSrc(links[0].src) : setSrc('');
        setProcessing(false)
      }
      setProcessing(false);
    }).catch(err => {
      setProcessing(false);
      console.log('err',err)
    })
  }, []);

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <Backdrop className={classes.backdrop} open={processing}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {src && (
        <iframe
          title={"fb"}
          src={src}
          scrolling={"no"}
          style={props.props.style}
          frameborder={"0"}
          allowTransparency={"true"}
          allow={"encrypted-media"}
          allowFullScreen={"false"}
        ></iframe>
      )}
      {!src && !processing && (
        <div >
          <h4 style={{ color: "white" }}>
            No Live Stream available at the moment
          </h4>
        </div>
      )}
    </div>
  );
};



const Embed = props => {


    const user = useContext(UserContext);

    useEffect(() => {
      if (!user) {
        // auth
        //   .signInAnonymously()
        //   .then(res => {
        //     console.log("res", res.user);
        //     //send call to backend to create key value pair
        //   })
        //   .catch(err => {});
      }
    }, [user]);

   if (props.channel.type === "facebook") {
      return (
        <Facebook props={props} />
      );
    }
    else if (props.channel.type === "youtube") {
      return (
        <Youtube props={props} />
      );
    }
    else if (props.channel.type === "twitch") {
      return (
        <Twitch props={props} />
      );
    }
    else {
      return (
        <div><h1>404 Not found</h1></div>
      )}


};

export default Embed;
