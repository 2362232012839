import React from "react";
import SignUpInfo from "../../components/sign-in-sign-up-tabs/SignUp/SignUpInfo/SignUpInfo";
import { ThemeProvider } from "@material-ui/core/styles";
import { outerTheme } from "../../themes/presets";
const AccountInfo = () => {
  return (
    <div style={{ overflow: "none" }}>
      <ThemeProvider theme={outerTheme}>
        <SignUpInfo></SignUpInfo>
      </ThemeProvider>
    </div>
  );
};

export default AccountInfo;
