import React from "react";
import FullWidthTabs from "../../components/sign-in-sign-up-tabs/sign-in-sign-up-tabs.component";
import { ThemeProvider } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { outerTheme } from "../../themes/presets";

const Auth = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <div className="Login" style={{ marginTop: "10px" }}>
        <div className="insertLogoHere" />
        <div className="row">
          <Container maxWidth={"sm"}>
            <ThemeProvider theme={outerTheme}>
              <FullWidthTabs />
            </ThemeProvider>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Auth;
