// an admin accessible page only
// review, approve & trigger payouts
import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { db } from "../../firebase";
import { UserContext } from "../../providers/UserProvider";
import { Button } from "@material-ui/core";
import { navigate } from "@reach/router";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const Review = () => {
  const classes = useStyles();
  const user = useContext(UserContext);
  const [payoutRequests, setPayoutRequests] = useState([]);

  const approveRequest = async uid => {
    await db.ref(`payoutRequests/${uid}`).remove();
    const earningSnapshot = await db.ref(`earnings/${uid}`).once("value");
    const earning = earningSnapshot.val();
    const processing = (earning.processing += earning.latest);
    await db.ref(`earnings/${uid}`).update({
      latest: 0,
      processing: processing
    });
    await db.ref(`payouts/${uid}`).set(true);
  };

  useEffect(() => {
    if (user) {
      if (!user.token.claims.admin) navigate("/dashboard");
      console.log(user.token.claims);
      (async () => {
        const requestsSnapshot = await db.ref(`payoutRequests/`).once("value");
        const requests = requestsSnapshot.val();
        const earningsSnapshot = await db.ref(`earnings/`).once("value");
        const earnings = earningsSnapshot.val();
        const creatorsSnapshot = await db.ref(`creators/`).once("value");
        const creators = creatorsSnapshot.val();
        const data = [];
        for (let key in requests) {
          if (earnings[key] && earnings[key].latest >= 0) {
            const requestObject = {
              latest: earnings[key].latest,
              name: creators[key].username,
              lastPayout: earnings[key].lastPayout || null,
              pendingPayout: earnings[key].processing,
              totalPaid: earnings[key].total,
              uid: key
            };
            data.push(requestObject);
          }
        }
        setPayoutRequests(data);
      })();
    }
  }, [user]);

  console.log(payoutRequests);
  return (
    <div>
      <h1>Pending Requests</h1>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell align="center">Current&nbsp;($)</TableCell>
              <TableCell align="center">Pending&nbsp;($)</TableCell>
              <TableCell align="center">Total&nbsp;($)</TableCell>
              <TableCell align="center">Last Payout</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payoutRequests.length > 0 &&
              payoutRequests.map(row => (
                <TableRow key={row.uid}>
                  <TableCell component="th" scope="row">
                    {row.username || row.uid}
                  </TableCell>
                  <TableCell align="center">{row.latest}</TableCell>
                  <TableCell align="center">{row.pendingPayout}</TableCell>
                  <TableCell align="center">{row.totalPaid}</TableCell>
                  <TableCell align="center">
                    {row.lastPayout || "n/a"}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      onClick={() => {
                        approveRequest(row.uid);
                      }}
                    >
                      Approve
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Review;
